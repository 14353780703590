import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User, Team, TeamRoles } from '../../../model/User';
import { FormControl, Validators } from '@angular/forms';
import { SelectOption } from '../form-control/form-control.component';
import { Subscriptions } from '../../../model/subscriptions';
import { AdminService } from '../../admin.service';
import { ToastService } from '../toasts/toast.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  prefix = "user-card.";
  @Input() user: User;
  @Input() team: Team;
  
  @Input() mode: "team" | "select" = "team";

  @Output() remove = new EventEmitter<User>();

  @Output() select = new EventEmitter<User>();

  showDropdown = false;

  roles: SelectOption[] = [
    { name: 'roles.' + TeamRoles.MEMBER, value: TeamRoles.MEMBER },
    { name: 'roles.' + TeamRoles.ADMIN, value: TeamRoles.ADMIN },
    { name: 'roles.' + TeamRoles.OWNER, value: TeamRoles.OWNER },
  ];
  roleControl = new FormControl(null, Validators.required);

  get currentRole() {
    if (!this.team) return 'none';
    const roles = this.user.getTeamRoles(this.team.id);
    return roles[0] || 'none';
  }

  get canDelete() {
    if (this.mode !== 'team') return false;
    return !this.user.hasRole(`owner`, `team:${this.team.id}`);
  }

  private _subs = new Subscriptions();

  constructor(
    private toasts: ToastService,
    private as: AdminService,
  ) { }

  ngOnInit(): void {
    this.roleControl.setValue(this.currentRole);
    this._subs.add(this.roleControl.valueChanges.subscribe(v => {
      if (!v) return;
      this.updateTeamRole(v);
    }));
  }

  clickCard() {
    if (this.mode === 'select') this.select.emit(this.user);
  }

  private async updateTeamRole(role: string) {
    //todo deal with this if not admin

    try {
      this.user.setTeamRole(this.team.id, role);
      await this.as.updateUser({ uuid: this.user.uuid, memberships: this.user.memberships });
      this.showDropdown = false;
    } catch (err) {
      this.toasts.error(err);
    }

  }

}
