import { Injectable } from '@angular/core';

import { Document } from '../model/Document';
import { ApiService } from './services/api.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private api: ApiService,
  ) { }

  async upload(data: any, blob: Blob): Promise<Document> {
    const body = data;
    
    const result = await this.api.post('documents', body)
    const doc = Document.deserialize(result.document);

    await this.api.putFile(doc.putUrl, blob, doc.getContentType(), Boolean(data.public));
    return doc;
  }
}
