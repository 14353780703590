<div class="form-group position-relative" *ngIf="ready">
  <label for="password">{{ prefix + 'label' | translate }}</label>
  <input type="password" [formControl]="control" class="form-control" id="password"
    placeholder="{{ prefix + 'placeholder' | translate }}"
    [class.is-invalid]="control.invalid && control.touched"
    (blur)="showHelp = false" (focus)="showHelp = true">
  <span [ngClass]="'score-' + score" class="password-strength-indicator">
    <span></span><span></span><span></span><span></span>
  </span>

  <div *ngIf="showHelp" class="feedback card shadow text-sm hide-if-empty p-2">
    <p *ngIf="score >= 3" class="text-success mb-1">
      <i class="fa fa-check-circle-o"></i>
      {{ prefix + 'help-good' |  translate }}
    </p>
    <strong *ngIf="score < 3 && feedback" class="mb-1">{{ prefix + 'help' |  translate }}</strong>
    <p *ngIf="feedback?.warning" class="text-danger mb-0">{{feedback.warning}}.</p>
    <p class="mb-0" *ngFor="let s of feedback?.suggestions">{{s}}</p>
  </div>
</div>