import { Injectable } from '@angular/core';
import { Contact } from 'src/model/Invoice';
import { ApiService } from './api.service';
import { Page } from '../../model/Page';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private _cache: { [key: string]: Page<Contact> } = {};

  constructor(
    private api: ApiService
  ) { }

  async search(q: string, pageNumber = 0): Promise<Page<Contact>> {
    const key = `${q || ''}::${pageNumber}`;
    if (this._cache[key]) return this._cache[key];
    
    const result = await this.api.get(`contacts?q=${encodeURIComponent(q || '')}&page=${encodeURIComponent(pageNumber)}` );
    
    const page = new Page<Contact>(result, Contact);
    this._cache[key] = page;
    return page;
  }

  async save(data: any): Promise<Contact> {
    if (data.id) return this.update(data);
    const result = await this.api.post('contacts', data);
    return Contact.deserialize(result.contact);
  }

  async update(data: any): Promise<Contact> {
    const result = await this.api.put('contacts', data);
    return Contact.deserialize(result.contact);
  }

  async delete(c: Contact) {
    await this.api.delete('contacts/' + c.id);
    c.active = false;
  }
}
