<div class="text-center">
  <div class="card signup-container shadow">

    <div class="row card-body text-left">
      <div class="col px-4 py-2 d-flex flex-column">

        <h3 class="text-uppercase text-gray-500 text-sm">{{ prefix + 'login' | translate }}</h3>
        <img src="assets/img/hello.png" class="px-2 py-2">

        <div class="mt-auto">
          <form (ngSubmit)="login()">
            <app-form-control [showLabel]="false" [showHelp]="false" [prefix]="prefix + 'email'"
              [control]="loginForm.get('email')">
            </app-form-control>
            <app-form-control [showLabel]="false" [showHelp]="false" [prefix]="prefix + 'password'"
              type="password" [control]="loginForm.get('password')"></app-form-control>

            <button class="btn btn-block" type="submit"
              [ngClass]="focusButton === 'login' ? 'btn-primary' : 'btn-outline-primary'">{{ prefix + 'submit' | translate }}</button>
          </form>
        </div>
      </div>

      <div class="col px-4 py-2 separator">
        <h3 class="text-uppercase text-gray-500 text-sm">{{ prefix + 'register' | translate }}</h3>
        <h1 class="my-4 text-gray-700">{{ prefix + 'hello' | translate }}</h1>
        <form (ngSubmit)="register()">
          <app-form-control [showLabel]="false" [showHelp]="false" [prefix]="prefix + 'email'"
            [control]="signupForm.get('email')">
          </app-form-control>
          <app-form-control [showLabel]="false" type="password" [showHelp]="false" [prefix]="prefix + 'password'"
            [control]="signupForm.get('password')"></app-form-control>
          <app-form-control [showLabel]="false" type="password" [showHelp]="false" [prefix]="prefix + 'password2'"
            [control]="signupForm.get('password2')"></app-form-control>

          <app-form-control [showHelp]="false" [prefix]="prefix + 'tos'" type="checkbox"
            [control]="signupForm.get('tos')">
            <a class="text-sm" href="#" target="_blank">{{ prefix + 'terms' | translate }}</a> -
            <a class="text-sm" href="#" target="_blank">{{ prefix + 'privacy-policy' | translate }}</a>
          </app-form-control>

          <button class="btn btn-block" type="submit"
            [ngClass]="focusButton === 'register' ? 'btn-primary' : 'btn-outline-primary'">{{ prefix + 'register-button' | translate }}</button>
        </form>
      </div>
    </div>

    <app-loader *ngIf="busy" [overlay]="true"></app-loader>
  </div>

  <ul class="list-inline mt-3 text-gray-300">
    <li class="list-inline-item">
      <a class="text-gray-500 text-hover-gray-700" (click)="passwordResetModal()" href="javascript:void(0)">{{ prefix + 'forgot' | translate }}</a>
    </li>
    <li class="list-inline-item">|</li>
    <li class="list-inline-item">
      <a class="text-gray-500 text-hover-gray-700" href="#"
        target="_blank">{{ prefix + 'privacy-policy' | translate }}</a>
    </li>
    <li class="list-inline-item">|</li>
    <li class="list-inline-item">
      <a class="text-gray-500 text-hover-gray-700" href="#" target="_blank">{{ prefix + 'terms' | translate }}</a>
    </li>

  </ul>
</div>