import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';

import { User } from '../../../model/User';

@Component({
  selector: 'app-password-form-control',
  templateUrl: './password-form-control.component.html',
  styleUrls: ['./password-form-control.component.scss']
})
export class PasswordFormControlComponent implements OnInit {
  @Input() control: FormControl;
  @Input() user: User;
  @Input() minStrength = 1;
  @Input() prefix = "password-reset.password.";

  ready = false;
  zxcvbn: Function;

  showHelp = false;

  get score() {
    return this.strength.score;
  }

  get feedback() {
    return this.strength.feedback;
  }

  strength = undefined;

  constructor() { }

  async ngOnInit() {
    //Lazy load zxcvbn to half bundle size
    this.zxcvbn = await import('zxcvbn').then(resp => resp.default);
    this.strength = this.zxcvbn('');
    this.ready = true;

    this.control.setValidators([Validators.required, this.passwordStrengthValidator(this)]);
    this.control.updateValueAndValidity();
  }

  passwordStrengthValidator(context: PasswordFormControlComponent) {
    return (ctrl: AbstractControl) => {
      if (!ctrl.value) return null;

      const inputs = [];
      if (context.user) {
        inputs.push(context.user.firstName, context.user.lastName, context.user.email);
      }

      const result = this.zxcvbn(ctrl.value, inputs);
      this.strength = result;
      if (result.score >= context.minStrength) return null;

      return { weakPassword: result };
    }
  }

}