<div class="card shadow-sm" [class.clickable]="mode === 'select'" (click)="clickCard()">
  <div class="card-body hover-parent">
    <div class="row">
      <div class="col-auto">
        <img [src]="user.avatar | avatar" class="avatar round">
      </div>
      <div class="col">
        <a *ngIf="canDelete" class="float-right hover-visible delete text-gray-500 text-hover-gray-600" href="javascript:void(0)"
          (click)="remove.emit(user)"><i class="material-icons">delete</i></a>
        <h2>{{ user.name }}</h2>

        <span *ngIf="!showDropdown && mode === 'team'" class="badge bg-white text-gray-600 mr-2 middle clickable" (click)="showDropdown = true">
          <span>{{currentRole}}</span>
        </span>

        <app-form-control *ngIf="showDropdown" [disableValidation]="true" type="select" [control]="roleControl" [options]="roles" [showLabel]="false" [showHelp]="false"></app-form-control>

      </div>
    </div>
  </div>
</div>