export function getNextReference(lastRef: number, minLength: number, prefix?: string ) {
  prefix = prefix || '';
  const length = minLength - prefix.length;

  let number = lastRef ? Number(lastRef) + 1 : 1;

  return `${prefix}${String(number).padStart(length, '0')}`;
}

export function constrain(n: number, min?: number, max?: number) {
  if (!isNaN(min)){
    if (n < min) n = min;
  }
  if (!isNaN(max)) {
    if (n > max) n = max;
  }

  return n;
}

export function joinpaths(...parts: string[]) {
  const result = parts.filter(p => p !== null && p !== undefined && p !== '').map(p => {
    return String(p).replace(/^\/*|\/*$/g, '');
  });
  return result.join("\\");
}

export function toQueryParams(obj: any, prefix?: string): string[] {
  if (!prefix) prefix = '';
  const params = [];
  for (let p of Object.getOwnPropertyNames(obj)) {
    if (obj[p] === null || obj[p] === undefined) continue;
    const result = toQueryParam(p, obj[p], prefix);
    if (result !== null) params.push(result);
  }
  return params;
}

function toQueryParam(name: string, value: any, prefix = ''): string {
  if (Array.isArray(value)) {
    const params = [];
    for (let v of value) {
      const p = toQueryParam(name, v, prefix);
      if (p !== null) params.push(p);
    }
    if (params.length === 0) return null;
    return params.join("&");
  } else if (typeof value === "object" && typeof value.unix === "function") {
    return `${prefix}${name}=${value.unix()}`;
  } else if (value === null || value === undefined) {
    return null;
  } else if (typeof value === "object") {
    //console.warn("Ignoring object", value);
    return null;
  } else if (typeof value === "boolean") {
    return `${prefix}${name}=${value ? 1 : 0}`;
  } else {
    return `${prefix}${name}=${encodeURIComponent(value)}`;
  }

}

export function getErrorCode(obj: any, depth = 0): string {
  if (!obj || depth > 5) return 'try-again-later';

  if (typeof obj === "string") {
    const code = obj.replace(/([^\w -]|[_])/g, '').replace(/\s/g, '-').toLowerCase();
    return code;
  }

  const value = obj.code || obj.reason || obj.error || obj.body || obj.message;

  if (!value) return 'try-again-later';

  return getErrorCode(value);
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function replaceNonAlphaNumerics(input: string): string {
  var combining = /[\u0300-\u036F]/g;
  return input.normalize('NFKD')
    .replace(combining, '')
    .replace(/[ß]/g, 'ss');
}

export function removeSpecialChars(input: string): string {
  const output = replaceNonAlphaNumerics(input)
    .replace(' ', '_')
    .replace(/[^a-zA-Z0-9 -]/, '');
  
    return output;
}