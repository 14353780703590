import { Injectable } from '@angular/core';

import { Invoice, SearchCriteria, InvoiceLine, Contact, Payment } from '../../model/Invoice';
import { Page } from '../../model/Page';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';
import { Serializer } from '../../model/Serializer';
import { DashStat } from '../../model/Stats';
import { Report } from '../../model/Report';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(
    private api: ApiService,
    private cache: CacheService,
  ) {
  }

  async getStats(currency: string) {
    const result = await this.api.get('invoices/stats/' + currency);
    return result.stats.map(d => DashStat.deserialize(d));
  }

  async getRecentInvoices(force = false): Promise<Invoice[]> {
    if (!force && this.cache.recentInvoices.value.length > 0) return this.cache.recentInvoices.value;
    const s = new SearchCriteria();
    s.page = 0;
    s.perPage = 5;
    s.includeLines = true;
    const result = await this.getInvoices(s);
    this.cache.recentInvoices.next(result.page.items.slice(0, 5));
    return result.page.items;
  }

  async getInvoices(search?: SearchCriteria, statsCurrency?: string): Promise<{ page: Page<Invoice>, stats: DashStat[], currencies: string[] }> {
    if (!search) search = new SearchCriteria();

    const statsParam = statsCurrency ? `&stats=${statsCurrency}` : "";
    const result = await this.api.get('invoices?' + search.toQueryParams().join("&") + statsParam);
    const page = new Page<Invoice>(result, Invoice);

    const stats = result.dashboardData?.map(d => DashStat.deserialize(d));
    return { page, stats, currencies: result.currencies };
  }

  async getInvoice(ref: string): Promise<Invoice> {
    const result = await this.api.get('invoices/' + ref);
    return Invoice.deserialize(result.invoice);
  }

  async newFromContact(c: Contact): Promise<Invoice> {
    const data = { toId: c.id, lines: [] };
    return this.save(data);
  }

  async newFromInvoice(i: Invoice): Promise<Invoice> {
    const data = { fromInvoiceId: i.id, toId: i.toId, lines: [] };
    return this.save(data);
  }

  async save(data: any): Promise<Invoice> {
    if (data.id) return this.update(data);

    const result = await this.api.post('invoices', data);
    return Invoice.deserialize(result.invoice);
  }

  async saveTemplate(data: Partial<InvoiceLine>): Promise<InvoiceLine> {
    if (data.templateId) return this.updateTemplate(data);

    const result = await this.api.post('invoices/templates', data);
    const t = InvoiceLine.deserialize(result.template);
    this.cache.templates.value.unshift(t);
    this.cache.templates.next(this.cache.templates.value);

    return t;
  }

  async updateTemplate(data: Partial<InvoiceLine>): Promise<InvoiceLine> {
    const result = await this.api.put('invoices/templates', data);
    const t = InvoiceLine.deserialize(result.template);

    const template = this.cache.templates.value.find(t => t.templateId === data.templateId);
    if (!template) return t;

    Object.assign(template, t);

    return t;
  }

  async deleteTemplate(t: InvoiceLine) {
    await this.api.delete('invoices/templates/' + t.templateId);
    const lines = this.cache.templates.value.filter(l => l.templateId !== t.templateId);
    this.cache.templates.next(lines);
  }

  async update(data: any) {
    const i = Serializer.serialize(Invoice.fromFormGroupData(data));
    const result = await this.api.put('invoices', i);
    return Invoice.deserialize(result.invoice);
  }

  async void(i: Invoice) {
    const result = await this.api.delete('invoices/' + i.reference);
    return Invoice.deserialize(result.invoice);
  }

  async unvoid(i: Invoice) {
    const result = await this.api.put('invoices/unvoid', { id: i.id });
    return Invoice.deserialize(result.invoice);
  }

  async addPayment(data: any): Promise<Payment> {
    console.log(data);
    const result = await this.api.post('invoices/payments', data);
    return Payment.deserialize(result.payment);
  }

  async deletePayment(p: Payment) {
    await this.api.delete('invoices/payments/' + p.id);
    p.active = false;
  }

  async getTaxReport(year: number, quarter?: number) {
    let url = `taxes/${year}`;
    if (quarter) url += `/${quarter}`;
    const result = await this.api.get(url);
    return Report.deserialize(result.report);
  }
}
