<div class="modal-header" *ngIf="showTitle">
  <h5 class="modal-title pull-left" [innerHtml]="prefix + 'title' | translate : params"></h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="no()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="showContent" [innerHtml]="prefix + 'content' | translate : params">
</div>
<div class="modal-footer">

  <div class="col px-1">
    <button type="button" (click)="no()"
      class="btn-block btn btn-outline-primary">{{ prefix + 'no' | translate }}</button>
  </div>
  <div class="col px-1">
    <button type="button" (click)="yes()" [ngClass]="confirmClass"
      class="btn-block btn">{{ prefix + 'yes' | translate }}</button>
  </div>
</div>