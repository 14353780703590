import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { environment } from '../../environments/environment';
import { CacheService } from './cache.service';
import { StoreService } from './store.service';
import { Captcha } from '../../model/Captcha';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers: any = {
    'X-Client-Name': 'poff-front',
    'X-Client-Version': '1',
    'X-UTC-Offset': moment().utcOffset().toString(),
  };

  constructor(
    private http: HttpClient,
    private cache: CacheService,
    private store: StoreService,
  ) { }

  public setToken(token: string) {
    this.headers.Authorization = `bearer ${token}`;
  }

  public setTeam(teamId: number) {
    this.headers['X-Team-Id'] = String(teamId);
  }

  public unsetToken() {
    delete this.headers.Authorization;
    delete this.headers['X-Team-Id'];
  }

  public async get(path: string): Promise<any> {
    return this.http
      .get(environment.api + path, { headers: this.headers })
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err));
  }

  public async delete(path: string): Promise<any> {
    return this.http
      .delete(environment.api + path, { headers: this.headers })
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err));
  }

  public async put(path: string, body: any): Promise<any> {
    return this.http
      .put(environment.api + path, body, { headers: this.headers })
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err));
  }

  async putFile(url: string, blob: Blob, contentType: string, isPublic: boolean) {
    const headers: any = { 'Content-Type': contentType };
    if (isPublic) headers['x-amz-acl'] = 'public-read';

    return this.http
      .put(url, blob, { headers })
      .toPromise()
      .catch((err) => this.handleErrors(err));
  }

  public async post(path: string, body: any): Promise<any> {
    return this.http
      .post(environment.api + path, body, { headers: this.headers })
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err))
  }

  private handleCommonResponse(response: any) {
    if (!response) return response;

    if (response.captcha && response.captcha.validated) {
      this.cache.captcha.next(Captcha.deserialize(response.captcha));
    }
    return response;
  }

  private async handleErrors(e: HttpErrorResponse) {
    await this.handleCommonResponse(e.error);
    if (e.status === 500) {
      //this.toasts.warning("errors.unknown");
      console.error("Internal Server Error", e);
    } else if (e.status === 0) {
      //this.toasts.warning("errors.service-down");
      console.error("Service is down", e);
    }
    console.log("HttpResponse error", e);
    throw e;
  }
}
