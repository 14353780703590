import { Moment } from 'moment';

import { Serializer } from './Serializer';

export class Captcha {
  uuid: string = null;
  svg: string = null;
  validated?: boolean = null;
  expires?: Moment = null;
  usage?: number = null;

  isValid(): boolean {
    if (!this.validated) return false;
    if (this.expires.isBefore()) return false;
    if (this.usage >= 5) return false;

    return true;
  }

  static deserialize(data: any): Captcha {
    const m = { expires: 'moment' };
    return Serializer.deserialize(Captcha, data, m);
  }
}