import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  prefix = "confirm-modal.";
  modalResult: boolean;
  params: any;
  showTitle = false;
  showContent = true;
  confirmClass = 'btn-primary';

  constructor(private modal: BsModalRef) { }

  ngOnInit() {
  }

  yes() {
    this.modalResult = true;
    this.modal.hide();
  }

  no() {
    this.modalResult = false;
    this.modal.hide();
  }

}
