import { Pipe, PipeTransform } from '@angular/core';
import { Document } from '../../model/Document';
import { DEFAULT_AVATAR, DEFAULT_TEAM_AVATAR } from '../../model/User';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'avatar',
})
export class AvatarPipe implements PipeTransform {

  transform(value: Document, type: 'team' | 'user' = 'user'): string {
    const defaultAvatar = type === 'user' ? DEFAULT_AVATAR : DEFAULT_TEAM_AVATAR;

    if (!value) return defaultAvatar;
    return value.getPath(environment.cdn);
}

}
