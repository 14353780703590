import 'moment/locale/en-gb.js';

import { Component } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

import { UserService } from './services/user.service';
import { TitleService } from './title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private lastUrl: string;

  constructor(
    private translate: TranslateService,
    private us: UserService,
    private router: Router,
    private ts: TitleService,
    private route: ActivatedRoute,
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en').subscribe(() => this.setTitle());

    moment.locale('en-gb');

    //Titles
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.lastUrl = event.urlAfterRedirects;
        this.setTitle();
      });

    this.init();
  }

  async init() {
    await this.us.init();
  }

  private setTitle() {
    if (!this.lastUrl) return;

    //Find data.
    const routeWithTitle = this.getRouteWithTitle(this.getLastChild(this.route.snapshot));
    if (routeWithTitle) {
      this.ts.setTitle(routeWithTitle.data.title);
      return;
    }

    //Fallback to path.
    const path = this.lastUrl.split('?')[0].split('#')[0].replace(/\//g, '.');
    if (path.startsWith('.invoices.')) {
      //Display invoice title
      const parts = path.split('.');
      parts.shift();
      const ref = parts.pop();
      this.ts.setTitle('invoice', { ref });
    } else if (path.length > 1) {
      this.ts.setTitle(path);
    } else {
      this.ts.setTitle();
    }
  }

  private getLastChild(route: ActivatedRouteSnapshot) {
    if (route.firstChild) return this.getLastChild(route.firstChild);
    return route;
  }

  private getRouteWithTitle(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.data.title) return route;

    if (route.parent) return this.getRouteWithTitle(route.parent);

    return null;
  }


}
