import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { User } from '../../../model/User';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../shared/toasts/toast.service';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss']
})
export class PasswordResetFormComponent implements OnInit, OnChanges {
  @Input() mode: "user" | "admin" | "token" = "user";

  @Input() user: User;
  @Input() token?: string;
  @Input() minStrength = 1;

  @Output() started = new EventEmitter<void>();
  @Output() completed = new EventEmitter<void>();
  @Output() error = new EventEmitter<any>();

  state: "idle" | "no-token" | "done" = "idle";

  triedSubmit = false;
  loading = false;
  form: FormGroup;

  get oldPassword() { return this.form.get('oldPassword'); }
  get password() { return this.form.get('password'); }
  get password2() { return this.form.get('password2'); }

  constructor(
    private toasts: ToastService,
    private as: AdminService,
    private us: UserService,
  ) {
    this.form = new FormGroup({
      uuid: new FormControl(),
      token: new FormControl(),
      oldPassword: new FormControl(),
      password: new FormControl(''),
      password2: new FormControl('')
    }, this.passwordsMatchValidator);
  }

  ngOnInit() {
    this.reset();
  }

  ngOnChanges() {
    if (!this.form) return;

    const oldPasswordCtrl = this.form.get('oldPassword');
    if (this.mode === "user") {
      oldPasswordCtrl.setValidators([Validators.required]);
    } else {
      oldPasswordCtrl.clearValidators();
    }
    oldPasswordCtrl.updateValueAndValidity();
  }

  
  passwordsMatchValidator(passwords: FormGroup) {
    if (passwords.get('password').value === passwords.get('password2').value) return null;

    return { notSame: true };
  }

  private reset() {
    this.form.reset();
    if (this.user) this.form.get('uuid').setValue(this.user.uuid);
    if (this.token) this.form.get('token').setValue(this.token);
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  async submit() {
    this.triedSubmit = true;
    this.form.markAllAsTouched();

    if (this.form.invalid) return;
    this.loading = true;

    const data = this.form.value;

    try {
      if (this.mode === "token") {
        //Reset with token
        await this.us.resetPassword(data.token, data.password);
      } else if (this.mode === "user") {
        await this.us.changePassword(data.oldPassword, data.password);
      } else {
        //Admin change password
        await this.as.changePassword(data.uuid, data.password)
      }

      this.state = 'done';
      this.toasts.show('toasts.password-reset');
      this.reset();
      this.completed.next();
    } catch (err) {
      console.log(err);
      this.toasts.error(err);
      if (err.error && err.error.code === 'invalid-password') {
        this.form.get('oldPassword').setErrors({ invalidPassword: true });
      }
      this.error.next(err);
    }
    this.loading = false;
  }
}
