<form (ngSubmit)="submit()">
  <div class="modal-header">
    <h5 class="modal-title pull-left pl-2">
      {{ prefix + 'title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <app-form-control class="col-6" [prefix]="prefix + 'name'" [control]="form.get('name')" [showHelp]="false"></app-form-control>

  </div>
  <div class="modal-footer bg-gray-200">
    <button type="button" (click)="close()" class="btn btn-min-width btn-outline-primary">{{ prefix + 'close' | translate }}</button>
    <button type="submit" class="btn-min-width btn-primary btn">{{ prefix + 'submit' | translate }}</button>
  </div>
</form>
<app-loader [overlay]="true" *ngIf="busy"></app-loader>