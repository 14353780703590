import { Moment } from 'moment';

import { Serializer } from './Serializer';
import { User } from './User';

export class Ticket {
  id?: number = null;
  uuid: string = null;
  userId?: number = null;
  teamId?: number = null;
  name: string = null;
  email: string = null;
  created: Moment = null;
  updated: Moment = null;
  subject?: string = null;
  body: string = null;
  type: TicketType = null;
  status: TicketStatus = null;

  user: Partial<User> = null;
  messages: Message[] = [];

  get isOpen(): boolean {
    return this.status !== 'resolved';
  }

  get icon() {
    const icons = {
      resolved: 'done',
      "waiting-for-client": "announcement"

    }
    return icons[this.status] || 'adjust';
  }

  get class() {
    const classes = {
      resolved: 'text-green-600',
      "waiting-for-client": "text-orange-500",

    };
    return classes[this.status] || 'text-gray-700';
  }

  static deserialize(data: any): Ticket {
    const m = {
      created: 'moment',
      updated: 'moment',
      user: d => User.deserialize(d),
      messages: d => d.map(d => Message.deserialize(d)),
    };

    return Serializer.deserialize(Ticket, data, m);
  }
}

export type TicketType = 'account' | 'billing' | 'feedback' | 'bug' | 'other';
export type TicketStatus = "new" | "open" | "waiting-for-client" | "resolved";

export class Message {
  id?: number = null;
  ticketId: number = null;
  created: Moment = null;
  body: string = null;
  name: string = null;
  email: string = null;
  userId?: number = null;
  fromSupport: boolean = null;
  user: User = null;

  static deserialize(data: any): Message {
    const m = {
      created: 'moment',
      user: d => User.deserialize(d),
    };

    return Serializer.deserialize(Message, data, m);
  }
}