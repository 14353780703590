import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Team } from '../../../model/User';
import { CacheService } from '../../services/cache.service';
import { Document } from '../../../model/Document';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-switch-team',
  templateUrl: './switch-team.component.html',
  styleUrls: ['./switch-team.component.scss']
})
export class SwitchTeamComponent implements OnInit {
  fromUrl: string;
  teamId: number;
  prefix = "switch-team.";

  from: Team;
  to: Team;


  constructor(
    route: ActivatedRoute,
    private router: Router,
    private us: UserService,
    cache: CacheService,
  ) {
    this.fromUrl = route.snapshot.queryParams.fromUrl || '/invoices';
    this.teamId = Number(route.snapshot.params.teamId);

    this.from = this.us.team;
    this.to = cache.teams.value.find(t => t.id === this.teamId);
  }

  async ngOnInit() {
    if (!this.teamId) {
      this.router.navigate([this.fromUrl]);
      return;
    }

    await this.us.switchTeam(this.teamId);

    this.router.navigate([this.fromUrl]);
  }

  getAvatar(a: Document) {
    if (!a) return 'assets/img/octahedron.svg';

    return a.getPath(environment.cdn);
  }

}
