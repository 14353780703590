import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CaptchaService } from '../../captcha.service';
import { ToastService } from '../toasts/toast.service';
import { CaptchaControlComponent } from '../captcha-control/captcha-control.component';
import { Captcha } from '../../../model/Captcha';

@Component({
  selector: 'app-captcha-modal',
  templateUrl: './captcha-modal.component.html',
  styleUrls: ['./captcha-modal.component.scss']
})
export class CaptchaModalComponent implements OnInit {
  prefix = "captcha.modal.";
  @ViewChild('captcha', { static: false }) captchaControl: CaptchaControlComponent;
  
  form: FormGroup;
  busy = false;
  modalResult: Captcha;

  constructor(
    private modal: BsModalRef,
    private cs: CaptchaService,
    private toasts: ToastService,
  ) {
    this.form = new FormGroup({
      uuid: new FormControl(null, Validators.required),
      text: new FormControl(null, Validators.required),
    });
  }
  ngOnInit() { 
  }

  close() {
    this.modal.hide();
  }

  async submit() {
    console.log(this.form.value);

    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    this.busy = true;
    try {
      const c = await this.cs.validate(this.form.value);
      this.modalResult = c;
      this.close();
    } catch (err) {
      this.captchaControl.refresh();
      this.toasts.error(err);
    }

    this.busy = false;
  }

}
