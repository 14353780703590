import { Moment } from 'moment';

import { ObjectMapping, Serializer } from './Serializer';
import { Session } from './Session';
import { Document } from './Document';

export class User {
  uuid: string = null;
  username: string = null;
  firstName?: string = null;
  lastName?: string = null;
  email: string = null;
  emailConfirmed?: Moment = null;
  emailConfirmToken?: string = null;
  emailConfirmTokenExpires?: Moment = null;
  session?: Session = null;
  created: Moment = null;
  lastActivity: Moment = null;
  active: Boolean = null;
  twoFactor: twoFactorType = null;

  memberships: Membership[] = [];

  avatarId?: number = null;
  avatar?: Document = null;
  avatarUrl?: string = null;

  utcOffset?: number = null;

  teams?: Team[] = null;

  get warnings(): string[] {
    const warnings = [];
    if (!this.active) warnings.push('disabled');

    if (!this.emailConfirmed) {
      if (this.emailConfirmTokenExpires.isBefore()) {
        //Not confirmed, and can't login
        warnings.push('confirmed-too-late');
      } else {
        //Not confirmed, but can still login
        warnings.push('not-confirmed');
      }
    }

    return warnings;
  }

  get name() {
    let name = `${this.firstName || ''} ${this.lastName || ''}`.trim();
    if (!name && this.email) {
      name = this.email.split("@")[0];
    }
    if (!name && this.username) {
      name = this.username;
    }
    return name;
  }

  setTeamRole(teamId: number, role: string) {
    const m = this.memberships.filter(m => m.app !== `team:${teamId}`);
    m.push({ app: `team:${teamId}`, role });
    this.memberships = m;
  }

  removeTeamRoles(teamId: number) {
    this.memberships = this.memberships.filter(m => m.app !== `team:${teamId}`);
  }

  getTeamRoles(teamId: number) {
    return this.memberships.filter(m => m.app === `team:${teamId}`).map(m => m.role);
  }

  hasRole(role: string, app?: string): boolean {
    if (app) {
      return Boolean(this.memberships.find(m => m.role === role && m.app === app));
    } else {
      return Boolean(this.memberships.find(m => m.role === role));
    }
  }

  hasTeam(teamId: number): boolean {
    return Boolean(this.memberships.find(m => m.app === `team:${teamId}`));
  }

  patch(data: User) {
    Object.assign(this, data);
  }

  static deserialize(data: any): User {

    const mapping: ObjectMapping = {
      created: 'moment',
      emailConfirmed: 'moment',
      emailConfirmTokenExpires: 'moment',
      lastActivity: 'moment',
      avatar: (data) => Document.deserialize(data),
      session: (data) => Session.deserialize(data),
      teams: (data) => data.map(d => Team.deserialize(d)),
    };
    let u = Serializer.deserialize<User>(User, data, mapping);

    return u;
  }
}

export class Team {
  id: number = null;
  name: string = null;
  created: Moment = null;
  referencePrefix: string = null;
  referenceMinLength: number = 7;
  logo?: string = null;
  currency: string = null;
  plan: string = null;

  avatarId?: number = null;
  avatar?: Document = null;
  avatarUrl?: string = null;
  lastReference = null;

  owner?: User = null;

  static deserialize(data: any): Team {
    const m = {
      created: 'moment',
      avatar: (data) => Document.deserialize(data),
      owner: (data) => User.deserialize(data),
    };

    return Serializer.deserialize(Team, data, m);
  }
}

export interface Credentials {
  email: string;
  password: string;
}

export interface Membership {
  id?: number;
  created?: Moment;
  app: string;
  role: string;
}

export interface RegisterCredentials extends Credentials {
  firstName?: string;
  lastName?: string;
  username?: string;
  memberships?: Membership[];
}

export interface RegisterResult {
  status: "ok" | "failed",
  user?: User,
  reason?: string,
}

export interface TwoFactorData {
  type: twoFactorType;
  secret?: string;
  confirmed?: boolean;
  expires: Moment;
  userToken: string;
  otpAuthUrl?: string;
}

export type twoFactorType = "totp" | "email";

export enum Approles {
  USER = 'user',
  SUPER_ADMIN = 'super-admin',
  TESTER = 'tester',
}

export enum TeamRoles {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
}

export const DEFAULT_AVATAR = 'assets/img/avatar-octopus.svg';
export const DEFAULT_TEAM_AVATAR = 'assets/img/octahedron.svg';