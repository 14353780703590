import { Moment } from 'moment';

import { ObjectMapping, Serializer } from './Serializer';
import { joinpaths } from './helpers';

export class Document {
  id: number = null;
  uuid: string = null;
  created: Moment = null;
  originalFilename: string = null;
  filename: string = null;
  public: boolean = null;
  uploaded: boolean = null;
  putUrl?: string = null;
  path?: string = null;
  userUuid: string = null;

  getPath(prefix?: string): string {
    return joinpaths(prefix, this.path, this.filename);
  }

  getContentType() {
    let contentType: string;
    const extension = this.originalFilename.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      contentType = "application/pdf";
    } else if (extension === 'jpg') {
      contentType = "image/jpeg";
    } else {
      contentType=  `image/${extension}`
    }
    return contentType;
  }

  static newAvatar() {
    const d = new Document;
    d.public = true;
    return d;
  }

  static deserialize(data: any): Document {
    const m: ObjectMapping = {
      'created': 'moment'
    }

    return Serializer.deserialize(Document, data, m);
  }
}