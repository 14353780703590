import { Inject, LOCALE_ID, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { CurrencyPipe, DecimalPipe, getCurrencySymbol, getLocaleNumberFormat, getLocaleNumberSymbol, NumberFormatStyle, NumberSymbol } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { CacheService } from '../services/cache.service';

@Pipe({
  name: 'booksCurrency'
})
export class BooksCurrencyPipe implements PipeTransform {
  currencyPipe: CurrencyPipe;

  constructor(@Inject(LOCALE_ID) locale: string, private cache: CacheService) {
    this.currencyPipe = new CurrencyPipe(locale);
  }

  transform(value: any, code = 'EUR', display = 'symbol', digits?: string, locale?: string) {
    const currency = this.cache.currencies.value.find(c => c.name === code);
    if (currency) {
      digits = `1.${currency.decimals}-2`;
      display = currency.sign;
    }

    return this.currencyPipe.transform(value, code, display, digits, locale);
  }
}

@Pipe({
  name: 'shortCurrency'
})
export class ShortCurrencyPipe implements PipeTransform {
  pipe: DecimalPipe;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private san: DomSanitizer,
  ) {
    this.pipe = new DecimalPipe(locale);
  }

  transform(value: any, code = 'EUR', digits?: string, locale?: string) {

    if (isNaN(value)) return this.addSign(this.pipe.transform(value, digits, locale), code);

    const n = Number(value);
    let result = n;
    let mag = '';

    if (n >= Math.pow(10, 8)) {
      result = n / 1000000;
      mag = 'M';
      digits = "1.0-3";
    }

    return this.addSign(this.pipe.transform(result, digits, locale), code, mag);
  }

  private addSign(value: string, code: string, mag: string = '') {
    const decimalSymbol = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    const parts = value.split(decimalSymbol);

    let decimalText = '';
    if (parts[1]) {
      const value = parts[1].length === 1 ? `${parts[1]}0` : parts[1];
      decimalText = `<small class="text-gray-600">${decimalSymbol}${value}</small>`;
    }
    
    const currencySymbol = getCurrencySymbol(code, 'narrow');
    const html = `<span class="text-sm">${currencySymbol}</span>${parts[0]}${decimalText}<small>${mag}</small>`;
    return this.san.sanitize(SecurityContext.HTML, html);
  }
}
