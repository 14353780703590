import { Injectable } from '@angular/core';
import { ToastConfig, ToastMessage, ToastResult } from './ToastMessage';
import { getErrorCode } from '../../../model/helpers';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: ToastMessage[] = [];

  constructor() { 
  }

  async error(textOrError: any = 'try-again-later') {
    if (typeof textOrError !== "string") textOrError = getErrorCode(textOrError);
    return this.show("errors." + textOrError);
  }

  async show(conf: string|ToastConfig): Promise<ToastResult> {
    const m = new ToastMessage(conf);
    this.toasts.push(m);
    m.startTimer();

    await m.deferred.promise;
    const index = this.toasts.findIndex(t => m === t);
    if (index > -1) this.toasts.splice(index, 1);

    return m.deferred.promise;
  }
}
