<label [class.square]="square" class="position-relative d-inline-block image-container clickable" for="file-{{id}}">
  <img #img class="placeholder-image image" [src]="imgSrc">

  <div class="overlay">
    <i class="m-auto material-icons">publish</i>
  </div>

</label>

<input type="file" class="custom-file-input d-none" id="file-{{id}}" [formControl]="form.get('file')"
  accept="image/*" (change)="fileSelected($event.target.files)">