<form (ngSubmit)="submit()" *ngIf="form">
  <div class="modal-header">
    <h5 class="modal-title pull-left pl-2">
      <ng-container *ngIf="template.id; else newHeader">{{ t.name }}</ng-container>
      <ng-template #newHeader>{{ prefix + 'new-title' | translate }}</ng-template>
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="p-2">

      <!-- Basics row -->
      <div class="row">
        <div class="col-4 text-gray-600">
          <h2>{{ prefix + 'basics-title' | translate }}</h2>
          <p [innerHtml]="prefix + 'basics-content' | translate"></p>
        </div>

        <div class="col">
          <app-form-control [prefix]="prefix + 'templateName'" [control]="form.get('templateName')" type="text"
            [showHelp]="false" [focusOnLoad]="true">
          </app-form-control>
          <app-form-control [prefix]="prefix + 'name'" [control]="form.get('name')" type="text" [showHelp]="false">
          </app-form-control>
          <app-form-control [prefix]="prefix + 'comment'" [control]="form.get('comment')" type="text"
            [showHelp]="false"></app-form-control>

          <app-form-control [prefix]="prefix + 'isHours'" [control]="form.get('isHours')" type="checkbox"
            [showHelp]="false">
          </app-form-control>

          <div class="row">

            <app-form-control class="col-md-6" [prefix]="form.value.isHours ? prefix + 'hours' : prefix + 'quantity'"
              [control]="form.get('quantity')" type="number" [showHelp]="false">
            </app-form-control>
            <app-form-control class="col-md-6" *ngIf="form.value.isHours" [prefix]="prefix + 'minutes'"
              [control]="form.get('minutes')" type="number" [showHelp]="false">
            </app-form-control>

            <app-form-control class="col-md-6" [prefix]="prefix + 'price'" [control]="form.get('price')" type="number"
              step=".01" [showHelp]="false">
            </app-form-control>
          </div>
        </div>
      </div>
      <!-- End Basics row -->

    </div>
  </div>
  <div class="modal-footer bg-gray-200">
    <button *ngIf="template.templateId && !isLast" type="button" (click)="delete()" class="mr-auto btn btn-link">{{ prefix + 'delete' | translate }}</button>
    <button type="button" (click)="close()" class="btn btn-outline-primary">{{ prefix + 'close' | translate }}</button>
    <button type="submit" class="btn-primary btn">{{ prefix + 'submit' | translate }}</button>
  </div>
  <app-loader *ngIf="busy" [overlay]="true"></app-loader>
</form>