import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnInit {
  @ViewChild('textInput') textInput: ElementRef<HTMLInputElement>;
  @ViewChild('textareaInput') textareaInput: ElementRef<HTMLTextAreaElement>;

  @Output() blur = new EventEmitter();
  @Input() control: AbstractControl;
  @Input() focusOnLoad = false;
  @Input() prefix = "form-control";
  @Input() type = "text";
  @Input() size = "";
  @Input() align: string;
  @Input() max: number;
  @Input() params: any;
  @Input() inline = false;
  @Input() min: number;
  @Input() disabled = false;
  @Input() step: number;
  @Input() showHelp = true;
  @Input() showLabel = true;
  @Input() rows = 5;
  private _options: any = []
  @Input() translateOptions = false;
  @Input() set options(value: (string | SelectOption)[]) {
    if (!value) return;
    if (value === this._options) return;

    const prefix = this.translateOptions ? this.prefix + '.options.' : '';
    const newOptions = value.map(d => {
      if (typeof d === "string") return { name: prefix + d, value: d };

      const newData = { ...d };
      newData.name = prefix + d.name;
      return newData;
    });
    this._options = newOptions;

    if (this.type === "select") {
      const nullOption = this._options.find(o => o.value === null);
      if (!nullOption) this._options.unshift({ name: 'common.no-selection', value: null });
    }

  }
  get options() {
    return this._options;
  }
  @Input() disableValidation = false;

  id: string;

  get errors() {
    return Object.getOwnPropertyNames(this.control.errors);
  }

  constructor() {
    this.id = `form-control-` + Math.round(Math.random() * 100000);
  }

  ngOnInit() { 
    if (this.focusOnLoad) this.setFocus();
  }

  setFocus() {
    setTimeout(() => {
      if (this.textInput) this.textInput.nativeElement.focus();
      if (this.textareaInput) this.textareaInput.nativeElement.focus();
    });
  }

  getValidationClass(): string {
    if (this.disableValidation) return '';
    if (!this.control.touched) return '';

    return (this.control.valid) ? 'is-valid' : 'is-invalid';
  }

}
export interface SelectOption {
  name: string;
  value: string | number;
}