import { Deferred } from 'src/model/Deferred';

export interface ToastConfig {
  text: string;
  dismissable?: boolean;
  buttonText?: string;
  delay?: number;
}

export class ToastMessage implements ToastConfig {
  text = "toast.default";
  buttonText = 'common.ok';
  dismissable = true;
  delay = 3000;

  deferred = new Deferred<ToastResult>();

  constructor(conf: string | ToastConfig) {
    if (typeof conf === "string") {
      this.text = conf;
    } else {
      Object.assign(this, conf);
    }
  }

  startTimer() {
    setTimeout(() => {
      if (!this.deferred.finished) this.deferred.resolve('timeout');
    }, this.delay);
  }
}

export type ToastResult = "timeout" | "button";