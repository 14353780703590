import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'website'
})
export class WebsitePipe implements PipeTransform {

  transform(value: string): string {
    if (!value.startsWith('http')) return `https://${value}`;
    return value;
  }

}
