import { AbstractControl } from '@angular/forms';
import { isValidBIC, isValidIBAN } from 'ibantools';

export function noDigitAsLastCharacter(c: AbstractControl) {
  const v = String(c.value);
  if (!v) return null;
  if (/\d$/.test(v)) {
    return { 'last-digit': true }
  }

  return null
}

export function ibanValidator(c: AbstractControl) {
  const value = String(c.value).replace(/ /g, '');

  if (isValidIBAN(value)) return null;

  return { 'iban-invalid': true };
}

export function bicValidator(c: AbstractControl) {
  const value = String(c.value).replace(/ /g, '');

  if (!value) return null;

  if (isValidBIC(value)) return null;

  return { 'bic-invalid': true };
}

export function phoneValidator(c: AbstractControl) {
  const value: string = c.value;
  if (!value) return null;

  //Starts with +
  //Allows digits (0-9), spaces ' ', and dashes '-'.
  //Between 6 and 25 characters (including non-numerics);

  if (value.match(/^(\+?)[0-9\ \-]{6,25}$/)) {
    return null;
  }
  return { 'phone-invalid': true }
}

export function urlValidator(c: AbstractControl) {
  const value: string = c.value;
  if (!value) return null;

  //https://www.regextester.com/93652
  if (value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
    return null;
  }
  return { 'url-invalid': true }
}