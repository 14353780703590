import { Moment } from 'moment';
import { Serializer, ObjectMapping } from './Serializer';

export class Session {
  id: number = null;
  userId: number = null;
  created: Moment = null;
  expires: Moment = null;
  token: string = null;

  static deserialize(data: any): Session {
    const mapping: ObjectMapping = {
      created: 'moment',
      expires: 'moment'
    };
    let s = Serializer.deserialize<Session>(Session, data, mapping);

    return s;
  }
}