import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { ToastService } from '../../shared/toasts/toast.service';
import { ModalService } from '../../services/modal.service';
import { RequestPasswordResetModalComponent } from '../../shared/request-password-reset-modal/request-password-reset-modal.component';
import { CaptchaService } from '../../captcha.service';
import { CaptchaModalComponent } from '../../shared/captcha-modal/captcha-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  prefix = "login.";
  state = 'login';

  busy = false;

  fromUrl: string;
  signupForm: FormGroup;
  loginForm: FormGroup;

  focusButton = 'login';

  constructor(
    private us: UserService,
    private cs: CaptchaService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private modals: ModalService,
  ) {
    this.fromUrl = route.snapshot.queryParams.fromUrl || '/invoices';

    //Hardcode invoices here to avoid infinite loops
    if (this.us.loggedIn) this.router.navigate(['/invoices']);

    this.signupForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      password2: new FormControl(null, Validators.required),
      tos: new FormControl(null, Validators.requiredTrue),
    });

    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    });

    //Button focus
    this.loginForm.valueChanges.subscribe(v => this.focusButton = 'login');
    this.signupForm.valueChanges.subscribe(v => this.focusButton = 'register');
  }

  ngOnInit() { }

  async register() {
    this.signupForm.markAllAsTouched();
    if (!this.signupForm.valid) return;

    this.busy = true;

    if (this.cs.requireCaptcha()) {
      const success = await this.modals.show(CaptchaModalComponent, { class: 'modal-dialog-down'});
      if (!success) {
        this.busy = false;
        this.toast.show('toasts.captcha-failed');
        return;
      }
    }

    try {
      await this.us.signup(this.signupForm.value);
      this.router.navigate(['/profile/setup']);
    } catch (err) {
      this.toast.error(err);
    }

    this.busy = false;
  }

  async login() {
    this.loginForm.markAllAsTouched();
    if (!this.loginForm.valid) return;

    this.busy = true;
    try {
      await this.us.login(this.loginForm.value);
      this.router.navigate([this.fromUrl]);
    } catch (err) {
      this.toast.error(err);
    }

    this.busy = false;
  }

  async passwordResetModal() {
    const email = this.loginForm.value.email || this.signupForm.value.email || null;
    await this.modals.show(RequestPasswordResetModalComponent, { initialState: { email } });
  }
}
