<ng-template #showError>
  <div class="modal-body">
    <app-empty [prefix]="prefix + 'image-error.'"></app-empty>
  </div>
  <div class="modal-footer position-relative">
    <button type="button" (click)="close()" class="btn btn-outline-primary btn-block">
      {{ prefix + 'close' | translate }}
    </button>
  </div>
</ng-template>

<ng-container *ngIf="!imageError; else showError">
  <div class="modal-body">

    <image-cropper
      [imageFile]="file"
      [maintainAspectRatio]="square"
      [transform]="transform"
      [canvasRotation]="canvasRotation"
      cropperMinWidth="50"
      cropperMinHeight="50"
      [resizeToWidth]="resizeToWidth"
      [onlyScaleDown]="true"
      format="png"
      (loadImageFailed)="loadImageFailed()"
      (imageCropped)="imageCropped($event)"
  ></image-cropper>

  </div>
  <div class="modal-footer position-relative">
    <button type="button" (click)="close()" class="btn btn-outline-primary middle">
      <i class="material-icons mr-2">cancel</i>
      <span>{{ prefix + 'cancel' | translate }}</span>
    </button>

    <button type="button" (click)="submit()" class="btn btn-primary middle">
      <i class="material-icons mr-2">crop</i>
      <span>{{ prefix + 'submit' | translate }}</span>
    </button>
  </div>
</ng-container>