import { Contact, InvoiceLine } from './Invoice';
import { ObjectMapping, Serializer } from './Serializer';
import { User, Team } from './User';
import { BankAccount, Company } from './Company';
import { Currency } from './Currency';

export class PoffSessionResponse {
  team?: Team = null;
  teams?: Team[] = null;
  user: User = null;
  currencies: Currency[] = null;
  templates?: InvoiceLine[] = null;
  recentContacts?: Contact[] = null;
  dashboardData: any = null;
  bankAccounts: BankAccount[] = null;
  companies: Company[] = null;

  constructor() {
    
  }

  static deserialize(data: any): PoffSessionResponse {
    const m: ObjectMapping = {
      team: (d) => Team.deserialize(d),
      user: (d) => User.deserialize(d),
      teams: (d) => d.map(d => Team.deserialize(d)),
      recentContacts: (d) => d.map(d => Contact.deserialize(d)),
      templates: (d) => d.map(d => InvoiceLine.deserialize(d)),
    };
    return Serializer.deserialize(PoffSessionResponse, data, m);
  }
}