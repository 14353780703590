import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

import { ToastService } from '../toasts/toast.service';
import { b64toBlob } from '../../../model/helpers';

@Component({
  selector: 'app-cropper-modal',
  templateUrl: './cropper-modal.component.html',
  styleUrls: ['./cropper-modal.component.scss']
})
export class CropperModalComponent implements OnInit {
  modalResult: Blob;
  prefix = "cropper-modal.";
  file: File;

  square = true;

  croppedImage: string;
  transform: ImageTransform = {};
  canvasRotation = 0;
  cropperLoaded = false;
  fileName: string;
  imageError = false;

  resizeToWidth = 300;

  constructor(
    private modal: BsModalRef,
    private toast: ToastService,
  ) { }

  ngOnInit() {
  }

  close() {
    this.modal.hide();
  }

  submit() {
    const data = this.croppedImage.replace(/^.*,/, '');
    this.modalResult = b64toBlob(data);

    this.modal.hide();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    if (this.cropperLoaded) return;
    this.cropperLoaded = true;
    if (event.width < 100 || event.height < 100) {
      //this.close();
      this.toast.error("image-too-small");
    }

  }

  loadImageFailed() {
    this.toast.error('image-load-failed');
    this.imageError = true;

    setTimeout(() => { this.close() }, 2000);
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

}
