import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InvoiceLine } from '../../../model/Invoice';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceService } from '../../services/invoice.service';
import { ToastService } from '../toasts/toast.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-preset-modal',
  templateUrl: './preset-modal.component.html',
  styleUrls: ['./preset-modal.component.scss']
})
export class PresetModalComponent implements OnInit {
  prefix = "profile.presets.modal.";
  form: FormGroup;
  template: InvoiceLine;
  modalResult: InvoiceLine;
  isLast: boolean;
  busy = false;

  constructor(
    private modals: ModalService,
    private modal: BsModalRef,
    private is: InvoiceService,
    private toasts: ToastService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.template.toTemplateFormGroup();
  }

  close() {
    this.modal.hide();
  }

  async delete() {
    this.busy = true;
    const confirmed = await this.modals.showConfirmAlert('profile.presets.confirm-delete.', this.template);
    if (!confirmed) return this.busy = false;

    await this.is.deleteTemplate(this.template);
    this.busy = false;
    this.close();
  }

  async submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    this.busy = true;

    try {
      const line = InvoiceLine.fromFormGroupData(this.form.value);
      const t = await this.is.saveTemplate(line);
      this.modalResult = t;
      if (this.template) Object.assign(this.template, t);

      this.close();
      const message = this.template.templateId ? 'toasts.contact-updated' : 'toasts.contact-added';
      this.toasts.show(message);
    } catch (err) {
      this.toasts.error(err);
    }
    this.busy = false;
  }

}
