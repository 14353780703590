import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Invoice } from 'src/model/Invoice';
import { Page } from '../../../model/Page';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss']
})
export class InvoiceTableComponent implements OnInit {
  prefix = "invoice-table.";
  @Input() page: Page<Invoice>;
  @Input() busy = false;
  @Output() getPage = new EventEmitter<number>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  toDetails(i: Invoice) {
    this.router.navigate([i.reference], { relativeTo: this.route });
  }

  stopProp(event?: any) {
    if (event) event.stopPropagation();
  }

  getStatusClass(i: Invoice) {
    const s = i.displayStatus;
    if (s === "overdue") return 'badge badge-danger px-2';
  }

}
