import { Moment } from 'moment';

import { ObjectMapping, Serializer } from './Serializer';

export class Email {
  id: number = null;
  from: string = null;
  to: string = null;
  subject: string = null;
  text: string = null;
  html?: string = null;
  maxRetries: number = null;
  attempt: number = null;
  status: string = null;
  error?: string = null;
  sent?: Moment = null;
  created: Moment = null;
  retryAfter?: Moment = null;
  template?: string = null;
  language?: string = null;

  static deserialize(data: any) {
    const m: ObjectMapping = {
      sent: 'moment',
      created: 'moment',
      retryAfter: 'moment'
    };
    return Serializer.deserialize(Email, data, m);
  }
}

export interface MailTemplate {
  id?: number;
  name: string;
  language?: string;
  subject: string;
  text: string;
  html?: string;
}
