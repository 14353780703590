<div class="card mw m-auto">

  <div class="card-body">

    <h1>{{prefix + 'title' | translate }}</h1>
    <app-password-reset-form (completed)="onComplete()" mode="token" [token]="token"></app-password-reset-form>
  </div>
</div>
<div class="row mw mx-auto mt-3">
  <div class="col">
    <a class="text-gray-600 text-hover-gray-700" [routerLink]="['/login']">{{ prefix + 'login' | translate }}</a>
  </div>
  <div class="col text-right">
    <a class="text-gray-600 text-hover-gray-700" href="javascript:void(0)" (click)="resetModal()">{{ prefix + 'forgot-password' | translate }}</a>
  </div>
</div>