import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RequireLogin, RequireAppRoles, WarmUp } from './auth-guard';
import { LoginComponent } from './pages/login/login.component';
import { Approles } from '../model/User';
import { NotFoundComponent } from './not-found/not-found.component';
import { SwitchTeamComponent } from './pages/switch-team/switch-team.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';

const routes: Routes = [
  { path: '', redirectTo: '/invoices', pathMatch: 'full' },
  { path: 'setup', redirectTo: '/profile/setup', pathMatch: 'full' },
  { path: 'login', canActivate: [WarmUp], component: LoginComponent },
  { path: 'confirm-email/:token', canActivate: [WarmUp], component: ConfirmEmailComponent, data: { title: 'confirm-email' } },
  { path: 'reset-password/:token', canActivate: [WarmUp], component: ResetPasswordComponent, data: { title: 'reset-password' } },
  { path: 'invoices', canActivate: [WarmUp, RequireLogin], loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule) },
  { path: 'contacts', canActivate: [WarmUp, RequireLogin], loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule) },
  { path: 'profile', canActivate: [WarmUp, RequireLogin], loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: 'switch-team/:teamId', canActivate: [WarmUp, RequireLogin], component: SwitchTeamComponent },
  { path: 'admin', canActivate: [WarmUp, RequireLogin, RequireAppRoles], data: { roles: [Approles.SUPER_ADMIN] }, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
