import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import moment, { Moment } from 'moment';

import { removeSpecialChars, toQueryParams } from './helpers';
import { Serializer } from './Serializer';
import { BankAccount, Company } from './Company';

export class Invoice {
  id?: number = null;
  reference: string = null;
  toId: number = null;
  to: Contact = null;
  due: Moment = null;
  created: Moment = null;
  paidAt?: Moment = null;
  discountCents?: number = null;
  currency: string = null;
  taxPercentage: number = null;
  token: string = null;
  status: string = null;
  bankAccountId: number = null;
  companyId: number = null;

  /* Stuff that's calculated */
  totalBeforeTax?: number = null;
  totalAfterTax?: number = null;
  tax?: number = null;
  paid?: number = null;
  open?: number = null;
  /* End calculated stuff */

  payments: Payment[] = [];
  lines: InvoiceLine[] = [];
  bankAccount: BankAccount = null;
  company: Company = null;

  get realAmount() {
    return this.totalAfterTax / 100;
  }

  get realTotalBeforeTax() {
    return this.totalBeforeTax / 100;
  }

  get realDiscount() {
    return -1 * (this.discountCents / 100);
  }

  get realTax() {
    return this.tax / 100;
  }

  get realOpen() {
    return this.open / 100;
  }

  get displayStatus() {
    if (this.status !== 'paid' && this.due.isBefore()) return 'overdue';
    return this.status;
  }


  get filename() {
    return `invoice-${removeSpecialChars(this.company.name)}-${this.reference}.pdf`; 
  }

  canEdit(): boolean {
    if (this.paidAt) return false;
    if (this.payments) {
      const p = this.payments.find(p => p.active);
      if (p) return false;
    }
    return true;
  }

  isComplete(): boolean {
    if (this.status === "void") return true;
    return (this.paid && this.paid === this.totalAfterTax);
  }

  addPayment(p: Payment) {
    this.payments.push(p);
    this.calculate();
    if (this.paidAt) return;
    if (this.open <= 0) { 
      this.status = 'paid';
      this.paidAt = p.date.clone();
    }
  }

  removePayment(p: Payment) {
    p.active = false;
    
    this.calculate();
    if (this.paidAt) return;
    if (this.open <= 0) { 
      this.status = 'paid';
      this.paidAt = p.date.clone();
    }
  }

  calculate() {
    this.totalBeforeTax = 0;
    this.totalAfterTax = 0;
    this.tax = 0;
    for (let l of this.lines) {
      l.calculate();
      this.totalBeforeTax += l.total;
    }
    
    this.totalBeforeTax -= this.discountCents;
    this.tax = Math.floor(this.totalBeforeTax * (this.taxPercentage / 100));
    this.totalAfterTax = this.totalBeforeTax + this.tax;
    
    this.calculatePayments();
  }

  calculatePayments() {
    this.paid = 0
    for (let p of this.payments) {
      if (!p.active) continue;
      this.paid += p.cents;
    }

    this.open = this.totalAfterTax - this.paid;
  }

  static fromFormGroupData(value: any): Invoice {
    const data = { ...value };
    data.discountCents = Math.abs(Number(data.discount) * 100);

    if (data.due) data.due = moment(data.due).unix();
    if (data.created) data.created = moment(data.created).unix();
    
    const i = this.deserialize(data);
    i.lines = data.lines.map(l => InvoiceLine.fromFormGroupData(l));
    i.payments = value.payments || [];
    i.calculate();
    return i;
  }

  toFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(this.id),
      reference: new FormControl(this.reference),
      bankAccountId: new FormControl(this.bankAccountId),
      companyId: new FormControl(this.companyId),
      toId: new FormControl(this.toId),
      due: new FormControl(this.due ? this.due.toDate() : new Date()),
      created: new FormControl(this.created ? this.created.toDate() : new Date()),
      discount: new FormControl(this.discountCents ? (this.discountCents / 100) * -1 : 0),
      currency: new FormControl(this.currency),
      taxPercentage: new FormControl(this.taxPercentage, [Validators.min(0), Validators.max(500)]),
      token: new FormControl(this.token),
      status: new FormControl(this.status),
      payments: new FormControl(this.payments),
      lines: new FormArray(this.lines.map(l => l.toFormGroup())),
    });
  }

  static deserialize(data: any): Invoice {
    const m = {
      created: 'moment',
      due: 'moment',
      paidAt: 'moment',
      lines: (d) => d.map(d => InvoiceLine.deserialize(d)),
      to: (d) => Contact.deserialize(d),
      company: (d) => Company.deserialize(d),
      payments: (d) => d.map(d => Payment.deserialize(d))
    };

    return Serializer.deserialize(Invoice, data, m);
  }
}

export class InvoiceLine {
  id?: number = null;
  name: string = null;
  comment?: string = null;
  quantity: number = null;
  minutes: number = null;
  cents: number = null;
  invoiceId?: number = null;
  isHours: boolean = null;
  templateId?: number = null;
  isTemplate?: boolean = null;
  templateName?: string = null;
  total?: number = null;

  deleted: boolean = null;
  order: number = null;

  get realTotal() {
    return this.total / 100;
  }

  get realPrice() {
    return this.cents / 100;
  }

  calculate() {
    if (this.deleted) return;
    
    this.total = this.quantity * this.cents;
    if (this.isHours) {
      const fraction = this.minutes / 60;
      this.total += fraction * this.cents;
    }

    this.total = Math.floor(this.total);
  }

  toTemplateFormGroup(): FormGroup {
    return new FormGroup({
      templateId: new FormControl(this.templateId),
      templateName: new FormControl(this.templateName, Validators.required),
      name: new FormControl(this.name, Validators.required),
      comment: new FormControl(this.comment),
      quantity: new FormControl(this.quantity, [Validators.required, Validators.min(0), Validators.required]),
      minutes: new FormControl(this.minutes, [Validators.min(0), Validators.max(59), Validators.required]),
      price: new FormControl(this.cents ? this.cents / 100 : 0, Validators.required),
      isHours: new FormControl(Boolean(this.isHours), Validators.required),
      deleted: new FormControl(this.deleted),
      order: new FormControl(this.order),
    });
  }

  toFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(this.id),
      name: new FormControl(this.name, Validators.required),
      comment: new FormControl(this.comment),
      quantity: new FormControl(this.quantity, [Validators.required, Validators.min(0)]),
      minutes: new FormControl(this.minutes, [Validators.min(0), Validators.max(59)]),
      price: new FormControl(this.cents ? this.cents / 100 : 0),
      isHours: new FormControl(this.isHours),
      deleted: new FormControl(this.deleted),
      order: new FormControl(this.order),
    });
  }

  static fromFormGroupData(v: any): InvoiceLine {
    if (v.price) v.cents = Math.round(v.price * 100);
    return this.deserialize(v);
  }

  static deserialize(data: any): InvoiceLine {
    const m = {};
    return Serializer.deserialize(InvoiceLine, data, m);
  }
}

export class Payment {
  id?: number = null;
  cents: number = null;
  invoiceId: number = null;
  comment?: string = null;
  method: string = null;
  date: Moment = null;
  transactionId?: number = null;
  email?: string = null;
  bankAccountId?: number = null;
  bankAccount?: BankAccount = null;
  active: boolean = null;

  get realAmount() {
    return this.cents ? this.cents / 100 : 0;
  }
  
  static deserialize(data: any): Payment {
    const m = {
      date: 'moment',
    };

    return Serializer.deserialize(Payment, data, m);
  }
}

export class Contact {
  id: number = null;
  name: string = null;
  company?: string = null;
  addressLine1?: string = null;
  addressLine2?: string = null;
  email?: string = null;
  phone?: string = null;
  currency: string = null;
  created: Moment = null;
  teamId: number = null;
  taxPercentage: number = null;
  active: boolean = null;
  dueDays: number = null;
  country: string = null;
  taxId: string = null;

  get displayName() {
    return this.company || this.name;
  }


  patch(data: any) {
    const props = ['name', 'company', 'addressLine1', 'addressLine2', 'email', 'phone', 'currency', 'taxPercentage', 'dueDays', 'taxId'];
    for (let p of props) {
      if (data[p] !== null && data[p] !== undefined) this[p] = data[p];
    }
  }

  static deserialize(data: any): Contact {
    const m = {
      created: 'moment',
    };

    return Serializer.deserialize(Contact, data, m);
  }

}

export class SearchCriteria {
  page: number = 0;
  perPage: number;
  contactId?: number;
  invoiceId?: string;
  includeLines?: boolean;
  includePayments?: boolean;

  toQueryParams(): string[] {
    return toQueryParams(this);
  }
}

export type ViewState = 'view' | 'edit' | 'new';