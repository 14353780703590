import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchControlComponent } from '../search-control/search-control.component';
import { Page } from '../../../model/Page';
import { Contact } from '../../../model/Invoice';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { ContactService } from '../../services/contact.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../toasts/toast.service';
import { CacheService } from '../../services/cache.service';

@Component({
  selector: 'app-contact-search-form',
  templateUrl: './contact-search-form.component.html',
  styleUrls: ['./contact-search-form.component.scss']
})
export class ContactSearchFormComponent implements OnInit {
  prefix = "contact-search.";

  @Output() contactAdded = new EventEmitter<Contact>();
  @Input() control: FormControl;
  @ViewChild('search') search: SearchControlComponent;

  contactPage: Page<Contact>;
  searching = false;

  get selectedContact(): Contact {
    return this.control.value;
  }

  constructor(
    private contacts: ContactService,
    private modals: ModalService,
    private toasts: ToastService,
    public cache: CacheService,
  ) { }

  ngOnInit(): void {
  }

  select(c: Contact) {
    this.control.setValue(c);
  }

  async addContact(isFirst = false) {
    const contact = await this.modals.show(ContactModalComponent, { class: 'modal-lg modal-dialog-scrollable', initialState: { isFirst } });
    if (!contact) return;

    this.control.setValue(contact);
    this.contactAdded.emit(contact);
    //this.createBlank();
  }

  isSelected(c: Contact): boolean {
    if (!this.selectedContact) return false;

    return this.selectedContact.id === c.id;
  }

  async searchContacts(event: string) {
    this.searching = true;
    try {
      this.contactPage = await this.contacts.search(event);
      
    } catch (er) {
      this.toasts.error();
    }
    this.searching = false;
  }

  resetContactSearch() {
    this.contactPage = null;
    this.search.reset();
  }

}
