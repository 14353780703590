import { Injectable } from '@angular/core';
import { Email } from 'src/model/Email';
import { TicketSearch } from 'src/model/TicketSearch';

import { Company } from '../model/Company';
import { MailTemplate } from '../model/Email';
import { toQueryParams } from '../model/helpers';
import { Invoice } from '../model/Invoice';
import { LiveSetting } from '../model/LiveSetting';
import { Page } from '../model/Page';
import { Ticket } from '../model/Ticket';
import { Team, User } from '../model/User';
import { ApiService } from './services/api.service';
import { CacheService } from './services/cache.service';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private api: ApiService,
    private us: UserService,
    private cache: CacheService,
  ) { }

  async userSearch(q: string, page = 0): Promise<Page<User>> {
    const result = await this.api.get(`admin/users?q=${encodeURIComponent(q)}&page=${page}`);

    return new Page(result, User);
  }

  async teamSearch(q: string, page = 0): Promise<Page<Team>> {
    const result = await this.api.get(`admin/teams?q=${encodeURIComponent(q)}&page=${page}`);

    return new Page(result, Team);
  }

  async getTeamInfo(teamId: number): Promise<{ team: Team, companies: Company[], userPage: Page<User>, invoiceCount: number }> {
    const result = await this.api.get(`admin/teams/${teamId}`);

    result.team = Team.deserialize(result.team);
    result.companies = result.companies.map(data => Company.deserialize(data));
    result.userPage = new Page<User>(result.userPage, User);

    return result;
  }

  async getTeamInvoices(teamId: number, page = 0): Promise<Page<Invoice>> {
    const result = await this.api.get(`admin/teams/${teamId}/invoices?page=${page}`);

    return new Page(result, Invoice);
  }

  async getTeamInvoice(teamId: number, reference: string): Promise<Invoice> {
    const result = await this.api.get(`admin/teams/${teamId}/invoices/${reference}`);
    return Invoice.deserialize(result.invoice);
  }

  async saveTeam(data: Partial<Team>): Promise<Team> {
    const result = await this.api.post('admin/teams', data);
    const user = User.deserialize(result.currentUser);
    this.us.user.memberships = user.memberships;
    this.us.userChanged.next(this.us.user);

    const team = Team.deserialize(result.team);
    this.cache.teams.value.push(team);
    this.cache.teams.next(this.cache.teams.value);

    return team;
  }

  async saveUser(data: any): Promise<User> {
    if (data.uuid) return this.updateUser(data);

    const result = await this.api.post('admin/users', data);
    return User.deserialize(result.user);
  }

  async updateUser(data: any): Promise<User> {
    const result = await this.api.put('admin/users', data);
    return User.deserialize(result.user);
  }

  async changePassword(uuid: string, password: string): Promise<void> {
    await this.api.put('admin/password', { uuid, password });
  }

  async getEmails(page: number): Promise<Page<Email>> {
    const result = await this.api.get(`admin/emails?page=${page}`);
    return new Page<Email>(result, Email);
  }

  async getEmailTemplates(): Promise<MailTemplate[]> {
    const result = await this.api.get(`admin/email-templates`);
    return result.templates;
  }

  async saveEmailTemplate(data: any): Promise<MailTemplate> {
    if (data.id) return this.updateEmailTemplate(data);

    const result = await this.api.post(`admin/email-templates`, data);
    return result.template;
  }

  async updateEmailTemplate(data: any): Promise<MailTemplate> {
    const result = await this.api.put(`admin/email-templates`, data);
    return result.template;
  }

  async removeEmailTemplate(name: string, language: string) {
    await this.api.delete(`admin/email-templates/${name}/${language}`);
  }

  async getSettings() {
    const result = await this.api.get('admin/settings');
    return result.settings.map(d => LiveSetting.deserialize(d));
  }

  async setSetting(setting: Partial<LiveSetting>) {
    const result = await this.api.put('admin/settings', setting)
    return LiveSetting.deserialize(result.setting);
  }

  async getTickets(search: TicketSearch) {
    const result = await this.api.get('admin/tickets?' + toQueryParams(search).join("&"))
    return new Page<Ticket>(result, Ticket)
  }


}
