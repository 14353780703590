import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Contact } from '../../../model/Invoice';
import { ContactService } from '../../services/contact.service';
import { ToastService } from '../toasts/toast.service';
import { CacheService } from '../../services/cache.service';
import { UserService } from '../../services/user.service';
import { phoneValidator } from '../../../model/Validators';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {
  prefix = "contacts.modal.";
  form: FormGroup;
  contact: Contact;
  modalResult: Contact;
  isFirst = false;

  currencies = []

  constructor(
    private modal: BsModalRef,
    private cs: ContactService,
    private toasts: ToastService,
    private cache: CacheService,
    us: UserService,
  ) {
    this.currencies = this.cache.currencies.value.map(c => c.name);
    
    this.form = new FormGroup({
      id: new FormControl(),

      name: new FormControl(null, Validators.required),
      company: new FormControl(null),
      phone: new FormControl(null, phoneValidator),
      email: new FormControl(null, Validators.email),

      addressLine1: new FormControl(),
      addressLine2: new FormControl(),
      country: new FormControl(),
      
      taxId: new FormControl(),
      currency: new FormControl(us.team.currency, Validators.required),
      taxPercentage: new FormControl(0, [Validators.required, Validators.min(0)]),
      dueDays: new FormControl(28, [Validators.required, Validators.min(1)]),
    });
  }

  ngOnInit(): void {
    if (this.contact) this.form.patchValue(this.contact);
  }

  close() {
    this.modal.hide();
  }

  async submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    try {
      const contact = await this.cs.save(this.form.value);
      this.modalResult = contact;
      if (this.contact) {
        this.contact.patch(contact);
      } else {
        this.cache.recentContacts.value.push(contact);
        this.cache.recentContacts.next(this.cache.recentContacts.value);
      }
      this.close();
      const message = this.contact ? 'toasts.contact-updated' : 'toasts.contact-added';
      this.toasts.show(message);
    } catch (err) {
      this.toasts.error(err);
    }
  }

}
