import { Serializer } from './Serializer';
export class LiveSetting {
  id?: number = null;
  name: string = null;
  value: string = null;
  type: settingType = null;
  description?: string = null;

  getValue() {
    if (this.type === "bool") return this.boolValue();
    if (this.type === "number") return Number(this.value);

    return this.value;
  }

  private boolValue(): boolean {
    if (!this.value) return false;
    if (this.value === "false") return false;
    if (this.value === "0") return false;
    if (this.value === "no") return false;

    return true;
  }

  static deserialize(data: any): LiveSetting {
    return Serializer.deserialize(LiveSetting, data);
  }
}

export type settingType = 'bool' | 'string' | 'number';