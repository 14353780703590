<div class="form-group">
  <label *ngIf="!hideLabel" class="mr-1" for="{{id}}">
    {{ prefix + '.label' | translate }}
  </label>

  <div class="input-container">
    <input [(ngModel)]="search" class="form-control" [typeaheadOptionField]="label" [typeahead]="dataSource"
      [typeaheadAsync]="true" typeaheadWaitMs="400" (typeaheadLoading)="changeTypeaheadLoading($event)"
      placeholder="{{ prefix + '.placeholder' | translate }}" (typeaheadOnSelect)="onSelect($event)">

    <button *ngIf="control.value && !searching" (click)="clear()" class="btn btn-link btn-clear btn-sm" type="button">
      <i class="fa fa-times"></i>
    </button>
    <span class="loader" *ngIf="searching">
      <i class="fa fa-spin fa-spinner"></i>
    </span>
  </div>

</div>