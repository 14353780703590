<form [formGroup]="form" (ngSubmit)="submit()">
  <fieldset [disabled]="loading">
    <!-- old Password -->
    <app-form-control *ngIf="mode === 'user'" [control]="oldPassword" type="password"
      prefix="password-reset.old-password"></app-form-control>

    <!-- Password -->
    <app-password-form-control [control]="form.get('password')" [user]="user" [minStrength]="minStrength"></app-password-form-control>

    <!-- Repeat Password -->
    <div class="form-group">
      <label class="" for="password2">{{ 'password-reset.password2.label' | translate }}</label>
      <input type="password" [formControl]="password2" class="form-control" id="password2"
        placeholder="{{ 'password-reset.password2.placeholder' | translate }}"
        [class.is-invalid]="form.errors?.notSame && password2.touched">
      <ng-container *ngIf="form.errors && password2.touched">
        <small class="form-text text-danger"
          *ngIf="form.errors?.notSame">{{ 'password-reset.password2.error-not-same' | translate }}</small>
      </ng-container>
    </div>

    <!-- Submit -->
    <button type="submit" class="btn btn-block btn-outline-primary">{{ 'password-reset.button' | translate }}</button>
  </fieldset>

  <app-overlay-spinner *ngIf="loading"></app-overlay-spinner>

</form>