import moment, { Moment } from 'moment';

import { Invoice, Contact } from './Invoice';
import { Serializer } from './Serializer';

export class Report {
  utcOffset = null;

  start: Moment = null;
  end: Moment = null;
  invoices: Invoice[] = [];

  summary: CurrencySummary = null;
  allCurrencies: CurrencySummary[] = [];
  countries: CountrySummary[] = [];
  contacts: ContactSummary[] = [];

  constructor() { }

  static deserialize(data): Report {
    const m = {
      start: 'moment',
      end: 'moment',
      invoices: (d) => d.map(d => Invoice.deserialize(d)),
      contacts: (d) => d.map(d => { return { ...d, contact: Contact.deserialize(d.contact) } }),
    }
    return Serializer.deserialize<Report>(Report, data, m);
  }

}

export interface ExchangeRates {
  base: string;
  rates: { [key: string]: number };
  date: string;
}

export interface CurrencySummary {
  currency: string;
  amount: number;
  positive: number;
  negative: number;
  taxAmount: number;
  invoiceCount: number;
  exchangeRate: number;
}

export interface CountrySummary {
  country: string;
  amount: number;
  positive: number;
  negative: number;
  taxAmount: number;
  invoiceCount: number;
}

export interface ContactSummary {
  contact: Contact;
  amount: number;
  positive: number;
  negative: number;
  taxAmount: number;
  invoiceCount: number;
}