import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';
import { Document } from '../../../model/Document';
import { DocumentService } from '../../document.service';
import { ModalService } from '../../services/modal.service';
import { CropperModalComponent } from '../cropper-modal/cropper-modal.component';
import { ToastService } from '../toasts/toast.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  @Input() prefix = "image-upload.";
  @Input() emptyPlaceholder = "female-avatar.svg";
  @Input() square = true; 
  @Input() purpose?: string;
  @Input() document?: Partial<Document>;
  @Input() public = false;
  @Input() resizeToWidth = 300;

  @Output() uploaded = new EventEmitter<Document>();
  @Output() progress = new EventEmitter<string>();

  form: FormGroup;
  busy = false;
  id: number;
  imgSrc: string|SafeResourceUrl = 'assets/img/female-avatar.svg';

  private _file: File;
  private _blob: Blob;

  constructor(
    private modals: ModalService,
    private toasts: ToastService,
    private ds: DocumentService,
    private san: DomSanitizer,
  ) { 
    this.form = new FormGroup({
      file: new FormControl(),
      originalFilename: new FormControl(),
      filename: new FormControl(),
    });

    this.id = Math.floor(Math.random() * 10000000);
  }

  ngOnInit(): void {
    if (this.emptyPlaceholder) this.imgSrc = 'assets/img/' + this.emptyPlaceholder;
    if (this.document) {
      this.form.patchValue(this.document);
      if (this.document.filename) this.imgSrc = this.document.getPath(environment.cdn);
    }
  }

  async fileSelected(files: File[]) {
    if (files.length === 0) return;
    this._file = files[0];
    this._blob = new Blob([files[0]]);

    const name = this._file.name.split(/[\\/]/).pop();
    this.form.get('originalFilename').setValue(name);

    const threshold = 1024 * 1024 * 5; //5 mb
    if (this._file.size > threshold) {
      this.toasts.error("file-too-big");
      this.reset();
      return;
    }

    await this.openCropper();
  }

  private reset() {
    this.form.reset(); 
    if (this.document) {
      this.form.patchValue(this.document);
    }
  }

  private async submit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      console.warn("Something is up.", this.form.value);
      return;
    }
    this.busy = true;
    this.progress.emit('start');

    const data = this.form.value;
    data.public = Boolean(this.public);

    try {
      const doc = await this.ds.upload(data, this._blob);
      doc.putUrl = null;
      this.uploaded.emit(doc);
      this.progress.emit('success');
    } catch (err) {
      this.toasts.error("document-upload-failed");
      this.reset();
      this.progress.emit('error');
    }

    this.busy = false;
  }

  private async openCropper() {
    this.busy = true;
    const result = await this.modals.show(CropperModalComponent, { class: 'modal-auto', ignoreBackdropClick: true, keyboard: false, initialState: { file: this._file, square: this.square, resizeToWidth: this.resizeToWidth } });
    if (!result) {
      this.reset();
      return false;
    }

    //Change filename to png
    const parts = this.form.get('originalFilename').value.split('.');
    const currentExt = parts.pop();
    if (currentExt !== 'png') {
      parts.push('png');
      const newName = parts.join('.');
      this.form.get('originalFilename').setValue(newName);
    }

    this._blob = result;
    this.busy = false;

    this.renderBlob();
    this.submit();
  }

  private renderBlob() {
    this.imgSrc = this.san.bypassSecurityTrustResourceUrl(URL.createObjectURL(this._blob));
  }

}
