<div class="d-inline-block mw-500 mb-4">
  <app-search-control #search [prefix]="prefix + 'contacts-search-bar'" [busy]="searching"
    (doSearch)="searchContacts($event)"></app-search-control>

  <div class="pt-2">
    <a (click)="addContact()" href="javascript:void(0)" class="middle text-sm">
      <i class="material-icons mr-2">add</i>
      <span>{{ prefix + 'new-contact' | translate }}</span>
    </a>
  </div>
</div>

<ng-container *ngIf="!contactPage && !searching">
  <h3>{{ prefix + 'recent-contacts' | translate }}</h3>
  <div class="contacts">
    <span [ngClass]="isSelected(c) ? 'bg-blue-300' : 'bg-gray-200'" class="badge middle text-normal mr-3 mb-3 clickable"
      (click)="select(c)" *ngFor="let c of cache.recentContacts | async">
      <i
        class="text-gray-500 material-icons mr-2">{{ c.company ? 'business' : 'perm_identity' }}</i><span>{{ c.displayName }}</span>
    </span>
  </div>
</ng-container>

<ng-container *ngIf="contactPage && !searching">
  <h3>{{ prefix + 'search-contacts' | translate }}</h3>
  <div class="contacts">
    <span [ngClass]="isSelected(c) ? 'bg-blue-300' : 'bg-gray-200'" class="badge middle text-normal mr-3 mb-3 clickable"
      (click)="select(c)" *ngFor="let c of contactPage.items">
      <i
        class="text-gray-500 material-icons mr-2">{{ c.company ? 'business' : 'perm_identity' }}</i><span>{{ c.displayName }}</span>
    </span>
  </div>
  <div class="empty-placeholder my-4" *ngIf="contactPage.items.length === 0">
    <div><em>{{ prefix + 'no-contacts' | translate }}</em></div>
    <button class="btn btn-link middle" (click)="resetContactSearch()">
      <i class="material-icons">clear</i>
      <span>{{prefix + 'reset' | translate }}</span>
    </button>
  </div>
</ng-container>

<div *ngIf="searching">
  <app-loader></app-loader>
</div>