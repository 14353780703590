import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() overlay: boolean = false;
  @HostBinding('class') get class() {
    return this.overlay ? 'overlay' : '';
  }

  constructor() { }

  ngOnInit(): void {
  }

}
