import { Injectable } from '@angular/core';
import { Captcha } from '../model/Captcha';
import { ApiService } from './services/api.service';
import { StoreService } from './services/store.service';
import { CacheService } from './services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  constructor(
    private store: StoreService,
    private cache: CacheService,
    private api: ApiService,
  ) { 
    cache.captcha.subscribe(c => {
      if (!c) return;
      console.log("storing captcha", c);
      this.store.setItem('captcha', c);
    });

    const stored = this.store.getItem<Captcha>('captcha', Captcha);
    if (stored) cache.captcha.next(stored);
  }

  requireCaptcha(): boolean {
    const saved = this.cache.captcha.value;
    if (!saved) return true;
    console.log(saved);
    return !saved.isValid();
  }

  async getCaptcha(force = false): Promise<Captcha> {
    if (!force && this.cache.captcha.value && this.cache.captcha.value.isValid()) {
      return this.cache.captcha.value;
    }
    
    const result = await this.api.get('captcha');
    return Captcha.deserialize(result.captcha);
  }

  async validate(data: Partial<Captcha>): Promise<Captcha> {    
    const result = await this.api.post('captcha', data);
    return Captcha.deserialize(result.captcha);
  }
}
