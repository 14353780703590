import { Subscription } from 'rxjs';

export class Subscriptions {
  private _subs: Subscription[] = [];

  add(sub: Subscription) {
    this._subs.push(sub);
  }

  unsubscribeAll() {
    for (let s of this._subs) {
      s.unsubscribe();
    }
    this._subs = [];
  }
}