import { Injectable } from '@angular/core';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { Deferred } from '../../model/Deferred';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _modals: ModalStackItem[] = [];

  constructor(
    private ms: BsModalService
  ) {
    ms.onHide.subscribe(() => {
      if (this._modals.length === 0) {
        console.error("I'm hiding a modal, but the stack is empty!");
      };

      const item = this._modals.pop();
      item.promise.resolve(item.modal.content.modalResult);
    });
  }

  show<T = Object>(content: any, config: ModalOptions<T> = {}): Promise<any> {
    if (!config) config = {};
    config.id = Math.round(Math.random() * 1000000);
    //If I don't assign a modal ID here, for some reason the modal gets fucked.
    const modal = this.ms.show(content, config);
    const promise = new Deferred<any>();

    this._modals.push({ modal, promise });
    return promise.promise;
  }

  async showConfirm(prefix: string, params?: any, size = 'modal-sm'): Promise<boolean> {
    return await this.show<ConfirmModalComponent>(ConfirmModalComponent, { class: `${size} modal-dialog-down`, initialState: { prefix, params } });
  }

  async showConfirmAlert(prefix: string, params?: any, size = 'modal-sm'): Promise<boolean> {
    return await this.show<ConfirmModalComponent>(ConfirmModalComponent, { class: `${size} modal-dialog-down`, initialState: { prefix, params, confirmClass: 'btn-danger' } });
  }

}

interface ModalStackItem {
  promise: Deferred<any>;
  modal: BsModalRef;
}