import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger("fade", [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-50%)' }),
        animate('.2s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.2s ease-out', style({ opacity: 0, transform: 'translateY(-50%)' }))
      ])
    ])
  ]
})
export class ToastComponent implements OnInit {

  constructor(public ts: ToastService) { }

  ngOnInit() {
  }

}
