<div class="row mb-3" *ngIf="!captcha?.validated">
  <div class="col-auto">
    <div [innerHtml]="getSvg()"></div>
    <div class="text-center">
      <a tabindex="99" href="javascript:void(0)" (click)="refresh()" class="middle text-sm">
        <i [class.rotate]="busy" class="material-icons text-sm">refresh</i>
        <span>{{ prefix + 'refresh' | translate }}</span>
      </a>
    </div>
  </div>
  <div class="col">
    <app-form-control #control [focusOnLoad]="focusOnLoad" class="mt-2 d-block" [showHelp]="false" [showLabel]="false" [control]="form.get('text')"
      [prefix]="prefix + 'text'"></app-form-control>
  </div>
</div>