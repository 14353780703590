import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  transform(value: Moment|Date, format: string = 'L'): unknown {
    if (!value) return '';

    if (!moment.isMoment(value)) value = moment(value);

    return value.format(format);
  }
}

@Pipe({
  name: 'fromNow'
})
export class FromNowPipe implements PipeTransform {

  transform(value: Moment|any): unknown {
    if (!value) return '';

    if (!moment.isMoment(value)) value = moment(value);

    return value.fromNow();
  }

}
