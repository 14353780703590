<form (ngSubmit)="doSearch.emit(control.value)">
  <div>
    <label class="mr-1" *ngIf="showLabel" for="{{id}}" [innerHtml]="prefix + '.label' | translate : params">
    </label>

    <div class="input-group position-relative">
      <input #textInput type="text" class="form-control" id="{{id}}" [formControl]="control"
        placeholder="{{ prefix + '.placeholder' | translate : params }}">

      <span class="icon text-gray-500">
        <i *ngIf="busy; else icon" class="material-icons rotate">autorenew</i>
        <ng-template #icon><i class="material-icons">search</i></ng-template>
      </span>

      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="submit">{{ prefix + '.search' | translate : params }}</button>
      </div>
    </div>

    <div *ngIf="showHelp">
      <small class="form-text"><span [innerHtml]="prefix + '.help' | translate : params"></span></small>
    </div>

    <ng-content></ng-content>
  </div>
</form>