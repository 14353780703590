import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceService } from '../../services/invoice.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Invoice, Payment } from '../../../model/Invoice';
import { CacheService } from '../../services/cache.service';
import { ToastService } from '../toasts/toast.service';
import { SelectOption } from '../form-control/form-control.component';
import { Subscriptions } from '../../../model/subscriptions';

@Component({
  selector: 'app-add-payment-modal',
  templateUrl: './add-payment-modal.component.html',
  styleUrls: ['./add-payment-modal.component.scss']
})
export class AddPaymentModalComponent implements OnInit, OnDestroy {
  prefix = "invoices.add-payment.";

  invoice: Invoice;
  form: FormGroup;
  showComment = false;
  busy = false;
  modalResult: Payment;

  accounts: SelectOption[] = [];
  private subs = new Subscriptions();

  constructor(
    private modal: BsModalRef,
    private is: InvoiceService,
    public cache: CacheService,
    private toast: ToastService,
  ) {
    this.form = new FormGroup({
      amount: new FormControl(),
      comment: new FormControl(),
      bankAccountId: new FormControl(),
      invoiceId: new FormControl(null, Validators.required),
      cents: new FormControl(null, Validators.required),
    });

    this.form.get('amount').valueChanges.subscribe(v => {
      const n = Number(v);
      if (isNaN(n)) return;
      this.form.get('cents').setValue(Math.round(n * 100));
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      invoiceId: this.invoice.id,
      amount: this.invoice.realOpen,
    })

    //If total is positive, probably set validators.min
    this.form.get('amount').setValidators([Validators.required, Validators.max(this.invoice.realOpen)]);
    
    this.subs.add(this.cache.bankAccounts.subscribe(accounts => {
      this.accounts = [];
      for (let a of accounts) {
        this.accounts.push({ name: a.name, value: a.id });
      }
      this.form.get('bankAccountId').setValue(this.invoice.bankAccountId);
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribeAll();
  }

  close() {
    this.modal.hide();
  }

  async submit() {
    this.busy = true;

    try {
      const payment = await this.is.addPayment(this.form.value);
      this.invoice.addPayment(payment)
      this.modalResult = payment;
      this.close();
    } catch (err) {
      this.toast.error(err);
    }

    this.busy = false;
  }

}
