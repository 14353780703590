<form (ngSubmit)="submit()">
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ prefix + 'title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mb-2">
      <span class="text-sm mr-2">{{ prefix + 'open-amount' | translate }}:</span>
      <strong>{{ invoice.realOpen | booksCurrency : invoice.currency }}</strong>
    </div>

    <app-form-control [prefix]="prefix + 'amount'" [control]="form.get('amount')" type="number" step=".01" min="1"
      [max]="invoice.realOpen" [focusOnLoad]="true"></app-form-control>
    <app-form-control [prefix]="prefix + 'method'" [control]="form.get('bankAccountId')" type="select"
      [options]="accounts"></app-form-control>

    <a *ngIf="!showComment; else comment" href="javascript:void(0)"
      (click)="showComment = true">{{ prefix + 'add-comment' | translate }}</a>
    <ng-template #comment>
      <app-form-control [prefix]="prefix + 'comment'" [control]="form.get('comment')" type="textarea">
      </app-form-control>
    </ng-template>

  </div>
  <div class="modal-footer">
    <button type="button" (click)="close()" class="btn btn-outline-primary">{{ prefix + 'close' | translate }}</button>

    <button type="submit" class="btn-primary btn">{{ prefix + 'submit' | translate }}</button>
  </div>
</form>