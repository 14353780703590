import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Page } from '../../../model/Page';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnInit, OnChanges {
  prefix = 'paging.';
  @Input() page: Page<any>
  @Input() maxSide = 2;
  @Input() busy?: boolean;
  @Output() pageChange = new EventEmitter<number>();

  @Input() position: "start" | "middle" | "end" = "end";

  pages = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.page) return;
    this.createPages();
  }

  private createPages() {
    this.pages = [];
    for (let i = 0; i <= this.page.lastPage; i++) {
      this.pages.push(i + 1);
    }
  }

  shouldShow(i: number) {
    const distance = Math.abs(i - this.page.currentPage);
    return distance <= this.maxSide;
  }

  showLastPage() {
    return (!this.shouldShow(this.page.lastPage -1));    
  }

  prev() {
    if (this.page.currentPage === 0) return;

    this.toPage(this.page.currentPage - 1);
  }

  next() {
    if (this.page.currentPage === this.page.lastPage) return;

    this.toPage(this.page.currentPage + 1);
  }

  toPage(n: number) {
    this.pageChange.emit(n);
  }

}
