import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private t: TranslateService,
    private title: Title,
  ) { }

  async setTitle(value?: string, params?: any) {
    const appName = this.t.instant('app');

    if (value === null || value === undefined) {
      this.title.setTitle(appName);  
      return;
    }

    value = value.replace(/^\./, '');
    const title = this.t.instant('titles.' + value, params);

    this.title.setTitle(`${title} - ${appName}`);
  }
}
