import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Approles, DEFAULT_AVATAR, Team, User } from '../../../model/User';
import { CacheService } from '../../services/cache.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user: User;
  team: Team;
  prefix = "navbar.";
  imgSrc: string = DEFAULT_AVATAR;

  showSlant = true;

  get isAdmin() {
    if (!this.user) return false;
    return this.user.hasRole(Approles.SUPER_ADMIN, 'poff');
  }

  constructor(
    private us: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public cache: CacheService,
  ) { }

  ngOnInit(): void {
    this.us.userChanged.subscribe(u => {
      this.user = u;
      if (!u) return;
      if (this.user.avatar && this.user.avatar.filename) this.imgSrc = this.user.avatar.getPath(environment.cdn);
    });

    this.us.teamChanged.subscribe(t => this.team = this.us.team);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        //See if any of the parents of the route has "slant: boolean" in their data
        const routeWithSlantData = this.getRouteWithSlantData(this.getLastChild(this.route.snapshot));
        if (routeWithSlantData) {
          this.showSlant = routeWithSlantData.data.slant;
          return;
        } else {
          this.showSlant = true;
        }
        
      });
  }

  logout() {
    this.us.logout();
  }

  getTeamAvatar(t: Team) {
    if (!t.avatar) return 'assets/img/octahedron.svg';
    return t.avatar.getPath(environment.cdn);
  }

  private getLastChild(route: ActivatedRouteSnapshot) {
    if (route.firstChild) return this.getLastChild(route.firstChild);
    return route;
  }

  private getRouteWithSlantData(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.data.slant !== undefined) return route;

    if (route.parent) return this.getRouteWithSlantData(route.parent);

    return null;
  }

  async switchTeam(t: Team) {
    if (t.id === this.team.id) return;
    this.router.navigate(['/switch-team/', t.id], { queryParams: { fromUrl: this.router.url } });
  }

}
