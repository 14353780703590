import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Contact } from '../../../model/Invoice';
import { FormControl } from '@angular/forms';
import { SearchEvent } from '../typeahead-control/typeahead-control.component';
import { ContactService } from '../../services/contact.service';
import { ToastService } from '../toasts/toast.service';
import { ModalService } from '../../services/modal.service';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';

@Component({
  selector: 'app-pick-contact-modal',
  templateUrl: './pick-contact-modal.component.html',
  styleUrls: ['./pick-contact-modal.component.scss']
})
export class PickContactModalComponent implements OnInit {
  prefix = "pick-contact.";
  contact: Contact;
  modalResult: Contact;

  control = new FormControl();

  constructor(
    private modals: ModalService,
    private modal: BsModalRef,
    private toasts: ToastService,
    private contacts: ContactService,
  ) { }

  ngOnInit(): void {
    if (this.contact) this.control.setValue(this.contact);
  }

  submit() {
    this.modalResult = this.control.value;
    this.close();
  }

  close() {
    this.modal.hide();
  }

  async createContact() {
    const contact = await this.modals.show(ContactModalComponent, { class: 'modal-lg modal-dialog-scrollable' });
    if (!contact) return;
    this.modalResult = contact;
    this.close();
  }

  async search(event: SearchEvent) {
    try {
      const result = await this.contacts.search(event.q);
      event.observer.next(result.items);
    } catch (er) {
      this.toasts.error();
    }
  }

}
