import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../shared/toasts/toast.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
  prefix = "confirm-email.";

  token: string;
  busy = true;

  constructor(
    route: ActivatedRoute,
    private us: UserService,
    private toasts: ToastService,
  ) { 
    this.token = route.snapshot.params.token;
  }

  async ngOnInit() {
    try {
      await this.us.confirmEmail(this.token).catch((err) => console.error(err));
    } catch (err) {
      this.toasts.error(err);
    }
    this.busy = false;
  }

}
