<section id="invoices-table">
  <app-loader *ngIf="!page"></app-loader>
  <table class="table table-hover" *ngIf="page">
    <thead class="text-uppercase">
      <tr class="table-bg-blue-100 table-text-blue-800">
        <th class="py-3 px-4">{{ prefix + 'table.client' | translate }}</th>
        <th class="py-3 px-4 text-right">{{ prefix + 'table.due' | translate }}</th>
        <th class="py-3 px-4 text-right">{{ prefix + 'table.created' | translate }}</th>
        <th class="py-3 px-4">{{ prefix + 'table.status' | translate }}</th>
        <th class="py-3 px-4 text-right">{{ prefix + 'table.amount' | translate }}</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr class="hover-parent table-bg-white clickable" *ngFor="let i of page.items" (click)="toDetails(i)">
        <td>
          <strong class="d-block">{{ i.to.displayName }}</strong>
          <span class="text-gray-600">{{i.reference}}</span>
        </td>

        <td class="text-right">
          <div>{{ i.due | moment }}</div>
          <span class="text-gray-600">{{i.due.fromNow()}}</span>
        </td>
        <td class="text-right">
          <div>{{ i.created | moment }}</div>
          <span class="text-gray-600">{{i.created.fromNow()}}</span>
        </td>

        <td>
          <div class="text-normal" [ngClass]="getStatusClass(i)">
            {{ 'invoices.status.' +  i.displayStatus | translate }}
          </div>
          <span class="text-gray-600">{{ i.paidAt | moment }}</span>
        </td>

        <td class="text-right">
          <strong class="text-lg">{{ i.realAmount | booksCurrency : i.currency }}</strong>
          <div *ngIf="i.realOpen > 0 && i.status === 'open'" class="text-gray-600">{{ prefix + 'open' | translate }}
            {{ i.realOpen | booksCurrency : i.currency }}</div>
        </td>

        <td class="text-right">
          <ul class="list-inline hover-visible">
            <li class="list-inline-item">
              <a href="/api/pdf/{{i.token}}/{{i.filename}}" target="_blank" (click)="stopProp($event)" class="d-block"
                href="javascript:void(0)" [tooltip]="prefix + 'download-pdf' | translate">
                <i class="material-icons">picture_as_pdf</i>
              </a>
            </li>

          </ul>
        </td>
      </tr>
    </tbody>
  </table>

  <app-paging [page]="page" (pageChange)="getPage.emit($event)">
    <div class="p-2" *ngIf="busy">
      <i class="material-icons rotate">autorenew</i>
    </div>
  </app-paging>
</section>