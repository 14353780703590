<div class="toast-container container clickthrough">

  <div *ngFor="let t of ts.toasts" [@fade] class="clickthrough">
    <div class="clickable alert alert-black" (click)="t.deferred.resolve('button')">
      <div class="row">
        <div class="col">
          {{ t.text | translate }}
        </div>
        <!-- <div class="col-auto">
          <button (click)="t.deferred.resolve('button')"
            class="btn btn-sm float-right btn-dark">{{ t.buttonText | translate }}</button>
        </div> -->
      </div>


    </div>

  </div>
</div>