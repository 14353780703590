import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContenteditableModule } from '@ng-stack/contenteditable';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ImageCropperModule } from 'ngx-image-cropper';

import { PasswordResetFormComponent } from '../profile/password-reset-form/password-reset-form.component';
import { AddPaymentModalComponent } from './add-payment-modal/add-payment-modal.component';
import { AvatarPipe } from './avatar.pipe';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { CropperModalComponent } from './cropper-modal/cropper-modal.component';
import { FormControlComponent } from './form-control/form-control.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { LoaderComponent } from './loader/loader.component';
import { FromNowPipe, MomentPipe } from './moment.pipe';
import { BooksCurrencyPipe, ShortCurrencyPipe } from './my-currency.pipe';
import { NavbarComponent } from './navbar/navbar.component';
import { NiceInputComponent } from './nice-input/nice-input.component';
import { PagingComponent } from './paging/paging.component';
import { PasswordFormControlComponent } from './password-form-control/password-form-control.component';
import { PickContactModalComponent } from './pick-contact-modal/pick-contact-modal.component';
import { PresetModalComponent } from './preset-modal/preset-modal.component';
import { SearchControlComponent } from './search-control/search-control.component';
import { ToastComponent } from './toasts/toast/toast.component';
import { TypeaheadControlComponent } from './typeahead-control/typeahead-control.component';
import { UserCardComponent } from './user-card/user-card.component';
import { WebsitePipe } from './website.pipe';
import { CreateTeamModalComponent } from './create-team-modal/create-team-modal.component';
import { RequestPasswordResetModalComponent } from './request-password-reset-modal/request-password-reset-modal.component';
import { CaptchaControlComponent } from './captcha-control/captcha-control.component';
import { CaptchaModalComponent } from './captcha-modal/captcha-modal.component';
import { ContactSearchFormComponent } from './contact-search-form/contact-search-form.component';
import { EmptyComponent } from './empty/empty.component';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';

@NgModule({
  declarations: [
    NavbarComponent,
    MomentPipe,
    FromNowPipe,
    ToastComponent,
    NiceInputComponent,
    ConfirmModalComponent,
    PickContactModalComponent,
    TypeaheadControlComponent,
    LoaderComponent,
    PagingComponent,
    AddPaymentModalComponent,
    FormControlComponent,
    SearchControlComponent,
    ContactModalComponent,
    ImageUploadComponent,
    CropperModalComponent,
    PresetModalComponent,
    BooksCurrencyPipe,
    ShortCurrencyPipe,
    PasswordResetFormComponent,
    PasswordFormControlComponent,
    AvatarPipe,
    WebsitePipe,
    UserCardComponent,
    CreateTeamModalComponent,
    RequestPasswordResetModalComponent,
    CaptchaControlComponent,
    CaptchaModalComponent,
    ContactSearchFormComponent,
    EmptyComponent,
    InvoiceTableComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ImageCropperModule,
    ContenteditableModule,
    FormsModule,
    TypeaheadModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    TooltipModule,
    BsDropdownModule,
    PopoverModule,
    TranslateModule,
  ],
  exports: [ 
    TranslateModule,
    BsDatepickerModule,
    PopoverModule,
    FormsModule,
    ReactiveFormsModule,
    NavbarComponent,
    TooltipModule,
    BsDropdownModule,
    ToastComponent,
    MomentPipe,
    FromNowPipe,
    TypeaheadControlComponent,
    NiceInputComponent,
    LoaderComponent,
    PagingComponent,
    FormControlComponent,
    SearchControlComponent,
    ContactModalComponent,
    ImageUploadComponent,
    BooksCurrencyPipe,
    ShortCurrencyPipe,
    PasswordResetFormComponent,
    PasswordFormControlComponent,
    AvatarPipe,
    WebsitePipe,
    UserCardComponent,
    CreateTeamModalComponent,
    RequestPasswordResetModalComponent,
    CaptchaControlComponent,
    CaptchaModalComponent,
    ContactSearchFormComponent,
    EmptyComponent,
    InvoiceTableComponent,
  ]
})
export class SharedModule { }
