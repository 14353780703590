import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../toasts/toast.service';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {
  @Input() showContent = true;
  @Input() prefix = 'empty.';
  @Input() img: string = null;
  @Input() imgClass = '';
  @Input() contentClass = '';

  constructor(private toasts: ToastService) { }

  ngOnInit(): void {
  }

  octopus() {
    this.toasts.show('empty.what-else');
  }

}
