import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { RequestPasswordResetModalComponent } from '../../shared/request-password-reset-modal/request-password-reset-modal.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  prefix = "reset-password.";

  token: string;

  constructor(
    private modals: ModalService,
    route: ActivatedRoute,
    private router: Router,
  ) { 
    this.token = route.snapshot.params.token;
  }

  ngOnInit(): void {
  }

  onComplete() {
    this.router.navigate(['/login']);
  }

  resetModal() {
    this.modals.show(RequestPasswordResetModalComponent);
  }

}
