import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { Observable, Subscriber } from 'rxjs';

export interface SelectOption {
  name: string;
  value: string | number;
}

@Component({
  selector: 'app-typeahead-control',
  templateUrl: './typeahead-control.component.html',
  styleUrls: ['./typeahead-control.component.scss']
})
export class TypeaheadControlComponent implements OnInit {
  @Output() doSearch = new EventEmitter<SearchEvent>();

  @Input() control: AbstractControl;
  @Input() prefix = "form-control";
  @Input() label = "label";
  @Input() valueProp: string;
  @Input() hideLabel = false;

  @Input() search = "";
  searching = false;
  dataSource: Observable<any[]>;
  id: string;

  constructor() { 
    this.id = `form-control-` + Math.round(Math.random() * 100000);
  }

  ngOnInit() {
    this.dataSource = new Observable((observer: Subscriber<any>) => {
      this.doSearch.emit({ observer, q: this.search });
    });
  }

  changeTypeaheadLoading(e: boolean): void {
    this.searching = e;
  }

  clear() {
    this.control.setValue(null);
    this.search = '';
  }

  onSelect(m: TypeaheadMatch) {
    if (m.item) {
      const value = this.valueProp ? m.item[this.valueProp] : m.item;
      this.control.setValue(value);
    }
  }

}

export interface SearchEvent {
  observer: Subscriber<any[]>;
  q: string;
}