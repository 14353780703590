import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from './services/user.service';
import { ToastService } from './shared/toasts/toast.service';

@Injectable({ providedIn: 'root' })
export class RequireAppRoles implements CanActivate {

  constructor(
    private router: Router,
    private us: UserService,
    private toasts: ToastService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this.us.ready;
    const user = this.us.userChanged.value;

    const roles = route.data.roles || [];
    if (route.data.role) roles.push(route.data.role);

    if (roles.length === 0) throw "Missing Role(s) for Route: " + state.url;
    
    let missing = [];
    for (let r of roles) {
      if (!user.hasRole(r, 'poff')) missing.push(r);
    }

    if (missing.length === 0) return true;
    console.warn("Missing role(s)", missing);
    this.toasts.error('missing-roles');
    
    this.router.navigate(['/']);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class RequireLogin implements CanActivate {

  constructor(
    private router: Router,
    private us: UserService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this.us.ready;
    if (this.us.loggedIn) return true;
    
    this.router.navigate(['/login'], { queryParams: { fromUrl: state.url } });
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class WarmUp implements CanActivate {

  constructor(
    private us: UserService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this.us.ready;
    return true;
  }
}