import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ToastService } from '../toasts/toast.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { getErrorCode } from '../../../model/helpers';
import { CaptchaControlComponent } from '../captcha-control/captcha-control.component';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-request-password-reset-modal',
  templateUrl: './request-password-reset-modal.component.html',
  styleUrls: ['./request-password-reset-modal.component.scss']
})
export class RequestPasswordResetModalComponent implements OnInit {
  prefix = "request-password-reset.";
  @ViewChild('captcha', { static: false }) captchaControl: CaptchaControlComponent;

  email?: string;
  form: FormGroup;
  busy = false;

  constructor(
    private modal: BsModalRef,
    private store: StoreService,
    private us: UserService,
    private toasts: ToastService,
  ) { 
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      captcha: new FormGroup({
        uuid: new FormControl(null, Validators.required),
        text: new FormControl(),
      })
    });
  }

  ngOnInit() {
    if (this.email) this.form.get('email').setValue(this.email);
  }

  close() {
    this.modal.hide();
  }

  async submit() {
    console.log(this.form.value);

    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    this.busy = true;
    try {
      await this.us.requestPasswordReset(this.form.value);
      this.toasts.show('toasts.requested-password-reset');
      this.close();
    } catch (err) {
      const code = getErrorCode(err);
      if (code === "user-not-found"){
        this.form.get('email').setErrors({ 'email-unknown': true });
      }
      this.captchaControl.handleError(err);
      this.toasts.error(err);
    }

    this.busy = false;
  }
}
