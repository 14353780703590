<form (ngSubmit)="submit()">
  <div class="modal-header">
    <h5 class="modal-title pull-left pl-2">
      {{ prefix + 'title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <app-form-control [prefix]="prefix + 'email'" [control]="form.get('email')" type="email" [showHelp]="false">
    </app-form-control>
    <app-captcha-control #captcha [form]="form.get('captcha')"></app-captcha-control>

    <div [innerHtml]="prefix + 'content' | translate"></div>

  </div>
  <div class="modal-footer bg-gray-200">
    <button type="button" (click)="close()"
      class="btn btn-link mr-auto">{{ prefix + 'close' | translate }}</button>
    <button type="submit" class="btn-min-width btn-primary btn">{{ prefix + 'submit' | translate }}</button>
  </div>
</form>
<app-loader [overlay]="true" *ngIf="busy"></app-loader>