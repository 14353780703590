import { Injectable } from '@angular/core';
import { Serializer } from '../../model/Serializer';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  static PREFIX = 'poff_';

  constructor() { }

  getItem<T>(key: string, type?: any): T {

    const value = localStorage.getItem(StoreService.PREFIX + key);
    if (!value === null) return null;

    const object = this.tryParse(value);
    if (!type) return object;
    if (typeof type.deserialize === "function") return type.deserialize(object);
    return Serializer.deserialize(type, object);
  }

  setItem(key: string, object: any) {
    const data = Serializer.serialize(object);
    const json = JSON.stringify(data);
    localStorage.setItem(StoreService.PREFIX + key, json);
  }

  removeItem(key: string) {
    localStorage.removeItem(StoreService.PREFIX + key);
  }

  private tryParse(data): any {
    try {
      return JSON.parse(data);
    } catch(err) {
      return data;
    }
  }
}
