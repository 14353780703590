<span [ngClass]="getValidationClass()" *ngIf="state === 'edit'; else valueOnly">
  <span *ngIf="filter === 'currency'">{{ getSymbol() }}</span>
  <div *ngIf="type !=='textarea'" class="input position-relative d-inline-block">
    <input [placeholder]="prefix + 'placeholder' | translate" (keydown)="keyDown($event)" [step]="step"
      [ngClass]="inputClass" [style.width.px]="inputSize" class="d-inline-block" [type]="type" [formControl]="control">
  </div>
  <span *ngIf="type === 'textarea'" propValueAccessor="innerText" [formControl]="control" class="textarea"
    [attr.data-placeholder]="prefix + 'placeholder' | translate" role="textbox" unformattedPaste contenteditable></span>
</span>

<div class="label pre" #label>{{ control.value }}</div>
<ng-template #valueOnly><span [ngclass]="filter === 'currency' ? 'nowrap' : 'pre'" class="">{{ getValue() }}</span></ng-template>