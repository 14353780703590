import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent implements OnInit {
  control = new FormControl('');
  @Input() prefix = "search-control";
  @Input() type = "text";
  @Input() params: any;
  @Input() showLabel = false;
  @Input() showHelp = false;
  id: string;

  @Input() busy: boolean = false;
  
  @Output('doSearch') doSearch = new EventEmitter<string>();

  constructor() { 
    this.id = `form-control-` + Math.round(Math.random() * 100000);
  }

  ngOnInit(): void {
  }

  reset() {
    this.control.reset();
  }
}
