<div class="form-group" [class.form-inline]="inline">
  <label class="mr-1" [class.col-form-label-lg]="size === 'lg'" [class.sr-only]="!showLabel"
    *ngIf="showLabel && type !== 'checkbox'" for="{{id}}" [innerHtml]="prefix + '.label' | translate : params">
  </label>

  <!-- Text / number / password -->
  <input #textInput [readonly]="disabled" [class.ml-auto]="inline" [class.form-control-lg]="size === 'lg'"
    [class.text-center]="align === 'center'"
    *ngIf="type === 'text' || type === 'number' || type === 'password' || type === 'email'" [type]="type"
    [ngClass]="getValidationClass()" class="form-control" id="{{id}}" [formControl]="control"
    placeholder="{{ prefix + '.placeholder' | translate : params }}" [max]="max" [min]="min" [step]="step"
    (blur)="blur.emit($event)">

  <!-- Textarea -->
  <textarea #textareaInput *ngIf="type === 'textarea'" [class.ml-auto]="inline" [ngClass]="getValidationClass()"
    class="form-control" id="{{id}}" [formControl]="control" [readonly]="disabled"
    placeholder="{{ prefix + '.placeholder' | translate : params }}" [rows]="rows" (blur)="blur.emit($event)">
    </textarea>

  <!-- Select -->
  <select *ngIf="type === 'select'" [class.ml-auto]="inline" class="form-control custom-select" id="{{id}}"
    [formControl]="control" [ngClass]="getValidationClass()" (change)="blur.emit($event)">
    <option *ngFor="let o of options" [ngValue]="o.value">{{ o.name | translate : params }}</option>
  </select>

  <!-- radios -->
  <ng-container *ngIf="type === 'radios'">
    <div class="form-check" *ngFor="let o of options">
      <label class="form-check-label" [class.bolder]="control.value === o.value">
        <input class="form-check-input" type="radio" [value]="o.value" [formControl]="control">
        <span [innerHtml]="o.name | translate : params"></span>
      </label>
    </div>
  </ng-container>

  <!-- checkbox -->
  <div class="custom-control custom-checkbox" *ngIf="type === 'checkbox'">
    <input class="custom-control-input" type="checkbox" value="" id="{{id}}" [formControl]="control"
      (change)="blur.emit($event)">
    <label class="custom-control-label" for="{{id}}" [innerHtml]="prefix + '.label' | translate : params">
    </label>
  </div>

  <div *ngIf="showHelp">
    <small class="form-text"><span [innerHtml]="prefix + '.help' | translate : params"></span></small>
  </div>

  <ng-container *ngIf="control.invalid && control.touched && !disableValidation">
    <div *ngFor="let e of errors">
      <small class="form-text text-danger">{{ prefix + '.error-' + e | translate : control.errors }}</small>
    </div>
  </ng-container>
  <ng-content></ng-content>
</div>