import { Document } from './Document';
import { ObjectMapping, Serializer } from './Serializer';

export class Company {
  id?: number = null;
  name?: string = null;
  contactName: string = null;
  addressLine1: string = null;
  addressLine2: string = null;
  country: string = null;
  cocNumber?: string = null;
  taxId?: string = null;
  phone?: string = null;
  email?: string = null;
  website?: string = null;
  footerText?: string = null;
  logoPosition?: string = null;
  isDefault?: boolean = null;

  logoUrl?: string = null;
  logoId?: number = null;
  logo?: Document = null;

  get displayName() {
    return this.name || this.contactName;
  }

  static deserialize(data: any): Company {
    const m: ObjectMapping = {
      logo: data => Document.deserialize(data),
    };

    return Serializer.deserialize(Company, data, m);
  }
}

export interface BankAccount {
  id?: number;
  name: string;
  type: BankAccountType;
  number: string;
  accountHolder?: string;
  routingNumber?: string;
  swift?: string;
  bankCode?: string;
  bankName?: string;
  wireRoutingNumber?: string;
  bsbCode?: string;
  sortCode?: string;
  isDefault: boolean;
  teamId?: number;
}

export type BankAccountType = 'iban' | 'us' | 'uk' | 'au' | 'id' | 'paypal' | 'transferwise' | 'other';