import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { constrain } from '../../../model/helpers';
import { BooksCurrencyPipe } from '../my-currency.pipe';
import { CacheService } from '../../services/cache.service';

@Component({
  selector: 'app-nice-input',
  templateUrl: './nice-input.component.html',
  styleUrls: ['./nice-input.component.scss']
})
export class NiceInputComponent implements OnInit {

  @Input() prefix = 'input.';
  @Input() control: FormControl;
  @Input() default: string = '';
  @Input() state: 'view' | 'edit' = 'view';
  @Input() type = "text";
  @Input() step = 1;
  @Input() inputMask: string;
  @Input() filter: string;
  @Input() filterParams: any|any[];
  @Input() inputClass = 'text-hover-blue-900';
  @Input() maxWidth: number = Infinity;

  @ViewChild('label', { static: true }) label: ElementRef;

  inputSize: number;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private cache: CacheService,
  ) { }

  ngOnInit(): void {
    if (this.control.value === undefined) this.control.setValue(this.default);

    this.control.valueChanges.subscribe(v => {
      this.updateSize();
    });

    this.updateSize();
  }

  getValidationClass(): string {
    if (!this.control.touched) return '';

    return (this.control.valid) ? 'is-valid' : 'is-invalid';
  }

  getSymbol() {
    return getCurrencySymbol(this.filterParams, 'wide');
  }
  
  private updateSize() {
    const padding = this.type === 'number' ? 40 : 25;
    const minWidth = this.type === "number" ? 0 : 100;
    setTimeout(() => {
      const rect = this.label.nativeElement.getBoundingClientRect();
      const calcWidth = Math.round(rect.width) + padding;
      this.inputSize = constrain(calcWidth, minWidth, this.maxWidth);
    });
  }

  getValue() {
    let value = this.control.value;
    if (this.filter === 'currency') {
      const params = Array.isArray(this.filterParams) ? this.filterParams : [this.filterParams];

      const p = new BooksCurrencyPipe(this.locale, this.cache);
      value = p.transform(value, ...params);
    }
    return value;
  }

  keyDown(event: KeyboardEvent) {
    if (!event.code.startsWith('Key')) return;
    if (!this.inputMask) return;

    if (this.inputMask === 'numbers') {
      if (String(event.key).match(/\D/)) return false;
    }
  }

}
