<div class="card mw m-auto">

  <div *ngIf="!busy" class="card-body">
    <h1>{{prefix + 'title' | translate }}</h1>
    <div [innerHtml]="prefix + 'content' | translate"></div>

    <a class="btn btn-primary mt-4 btn-min-width" [routerLink]="['/']">{{ prefix + 'home' | translate }}</a>
  </div>

  <div *ngIf="busy" class="card-body">
    <h1>{{prefix + 'busy-title' | translate }}</h1>
    <div [innerHtml]="prefix + 'busy-content' | translate"></div>
  </div>
</div>