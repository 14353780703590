<form (ngSubmit)="submit()">
<div class="modal-header">
  <h5 class="modal-title pull-left pl-2">
    <ng-container *ngIf="contact; else newHeader">{{ contact.displayName }}</ng-container>
    <ng-template #newHeader>
      <span *ngIf="!isFirst">{{ prefix + 'new-title' | translate }}</span>
      <span *ngIf="isFirst">{{ prefix + 'first-title' | translate }}</span>
    </ng-template>
  </h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="p-2">

    <!-- Basics row -->
    <div class="row">
      <div class="col-4 text-gray-600">
        <h2>{{ prefix + 'basics-title' | translate }}</h2>
        <p [innerHtml]="prefix + 'basics-content' | translate"></p>
      </div>
      
      <div class="col">
        <div class="row">
          <app-form-control class="col-6" [prefix]="prefix + 'name'" [control]="form.get('name')" type="text" [showHelp]="false" [focusOnLoad]="true"></app-form-control>
          <app-form-control class="col-6" [prefix]="prefix + 'company'" [control]="form.get('company')" type="text" [showHelp]="false"></app-form-control>
        </div>
        <app-form-control [prefix]="prefix + 'email'" [control]="form.get('email')" type="email" [showHelp]="false"></app-form-control>
        <app-form-control [prefix]="prefix + 'phone'" [control]="form.get('phone')" type="text" [showHelp]="false"></app-form-control>
      </div>
    </div>
    <!-- End Basics row -->

    <hr class="my-4">

    <!-- Address Row -->
    <div class="row">
      <div class="col-4 text-gray-600">
        <h2>{{ prefix + 'address-title' | translate }}</h2>
        <p [innerHtml]="prefix + 'address-content' | translate"></p>
      </div>
      
      <div class="col">
        <app-form-control [prefix]="prefix + 'address1'" [control]="form.get('addressLine1')" type="text" [showHelp]="false"></app-form-control>
        <app-form-control [prefix]="prefix + 'address2'" [control]="form.get('addressLine2')" type="text" [showHelp]="false" [showLabel]="false"></app-form-control>
        <app-form-control [prefix]="prefix + 'country'" [control]="form.get('country')" type="text" [showHelp]="false"></app-form-control>
      </div>
    </div>
    <!-- End Address Row -->

    <hr class="my-4">

    <!-- Settings Row -->
    <div class="row">
      <div class="col-4 text-gray-600">
        <h2>{{ prefix + 'settings-title' | translate }}</h2>
        <p [innerHtml]="prefix + 'settings-content' | translate"></p>
      </div>
      
      <div class="col">
        <app-form-control prefix="forms.currency" [control]="form.get('currency')" type="select" [translateOptions]="true" [options]="currencies" [showHelp]="false"></app-form-control>
        <app-form-control [prefix]="prefix + 'tax'" [control]="form.get('taxPercentage')" type="number" min="0" max="100"></app-form-control>
        <app-form-control [prefix]="prefix + 'due-days'" [control]="form.get('dueDays')" type="number" min="1"></app-form-control>
        <app-form-control [prefix]="prefix + 'tax-id'" [control]="form.get('taxId')" [showHelp]="false" type="text"></app-form-control>
      </div>
    </div>
    <!-- End Settings Row -->
  </div>
</div>
<div class="modal-footer bg-gray-200">
  <button type="button" (click)="close()" class="btn btn-outline-primary">{{ prefix + 'close' | translate }}</button>
  <button type="submit" class="btn-primary btn">{{ prefix + 'submit' | translate }}</button>
</div>
</form>