import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { InvoiceLine, Invoice, Contact } from '../../model/Invoice';
import { DashStat } from '../../model/Stats';
import { Company, BankAccount } from '../../model/Company';
import { Currency } from '../../model/Currency';
import { Team } from '../../model/User';
import { Captcha } from '../../model/Captcha';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  templates = new BehaviorSubject<InvoiceLine[]>([]);
  recentContacts = new BehaviorSubject<Contact[]>([]);
  recentInvoices = new BehaviorSubject<Invoice[]>([]);
  dashboardStats = new BehaviorSubject<DashStat[]>([]);
  paymentMethods = new BehaviorSubject<string[]>([]);

  companies = new BehaviorSubject<Company[]>([]);
  bankAccounts = new BehaviorSubject<BankAccount[]>([]);
  teams = new BehaviorSubject<Team[]>([]);
  currencies = new BehaviorSubject<Currency[]>([]);

  newBankAccount = new BehaviorSubject<BankAccount>(null);
  newCompany = new BehaviorSubject<Company>(null);

  captcha = new BehaviorSubject<Captcha>(null);

  constructor() { }
}
