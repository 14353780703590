import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User, Team } from '../../../model/User';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdminService } from '../../admin.service';
import { ToastService } from '../toasts/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-team-modal',
  templateUrl: './create-team-modal.component.html',
  styleUrls: ['./create-team-modal.component.scss']
})
export class CreateTeamModalComponent implements OnInit {
  prefix = "create-team-modal.";
  form: FormGroup;
  modalResult: Team;
  busy = false;

  constructor(
    private modal: BsModalRef,
    private as: AdminService,
    private toasts: ToastService,
    private router: Router,
  ) {
        
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
    });
  }

  ngOnInit() {
  }

  close() {
    this.modal.hide();
  }

  async submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    this.busy = true;

    try {
      const team = await this.as.saveTeam(this.form.value);

      this.modalResult = team;
      this.close();
      this.toasts.show('toasts.team-added');
      this.router.navigate(['/admin/teams/', team.id]);
    } catch (err) {
      this.toasts.error(err);
    }
    this.busy = false;
  }
}
