import { Moment } from 'moment';
import { Serializer, ObjectMapping } from './Serializer';

export class DashStat {
  start: Moment = null;
  end: Moment = null;

  currency: string = null;
  count: number = null;
  open: number = null;
  total: number = null;

  get realOpen() {
    if (!this.open) return 0;
    return this.open / 100;
  }

  get realTotal() {
    if (!this.total) return 0;
    return this.total / 100;
  }

  static deserialize(data: any): DashStat {
    const m: ObjectMapping = {
      start: 'moment',
      end: 'moment',
    };
    return Serializer.deserialize(DashStat, data, m);
  }
}