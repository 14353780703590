<nav aria-label="pagination" *ngIf="page?.lastPage > 0">
  <ul class="pagination" [ngClass]="'justify-content-' + position">
    <li><ng-content></ng-content></li>
    <li *ngIf="busy">
      <div class="p-2">
        <i class="material-icons rotate">autorenew</i>
      </div>
    </li>
    <!-- Prev button -->
    <li class="page-item" [class.disabled]="page.currentPage === 0">
      <button class="page-link" (click)="prev()" tabindex="-1" aria-disabled="true">
        <i class="material-icons">arrow_back_ios</i>
      </button>
    </li>

    <!-- First page and Dots -->
    <li class="page-item" *ngIf="!shouldShow(0)">
      <button class="page-link" (click)="toPage(0)">1</button>
    </li>
    <li class="page-item disabled" *ngIf="!shouldShow(1)">
      <button class="page-link">
        ...
      </button>
    </li>

    <!-- Page buttons -->
    <ng-container *ngFor="let p of pages; let index = index">
      <li *ngIf="shouldShow(index)" [class.active]="page.currentPage === index" class="page-item">
        <button class="page-link" (click)="toPage(index)">{{p}}</button>
      </li>
    </ng-container>

    <!-- Dots and Last page  -->
    <li class="page-item disabled" *ngIf="!shouldShow(page.lastPage - 1)">
      <button class="page-link">
        ...
      </button>
    </li>
    <li class="page-item" *ngIf="!shouldShow(page.lastPage)">
      <button class="page-link" (click)="toPage(page.lastPage)">{{page.lastPage + 1}}</button>
    </li>

    <!-- Next button -->
    <li class="page-item" [class.disabled]="page.currentPage === page.lastPage">
      <button class="page-link" (click)="next()">
        <i class="material-icons">arrow_forward_ios</i>
      </button>
    </li>

  </ul>
</nav>