import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { Captcha } from '../../../model/Captcha';
import { CaptchaService } from '../../captcha.service';
import { FormControlComponent } from '../form-control/form-control.component';
import { getErrorCode } from '../../../model/helpers';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-captcha-control',
  templateUrl: './captcha-control.component.html',
  styleUrls: ['./captcha-control.component.scss']
})
export class CaptchaControlComponent implements OnInit {
  @ViewChild('control', { static: false }) ctrl: FormControlComponent;

  @Input() form: FormGroup;
  @Input() focusOnLoad = false;

  prefix = "captcha.";

  captcha: Captcha;
  busy = false;

  constructor(
    private san: DomSanitizer,
    private cs: CaptchaService,
    private store: StoreService,
  ) { }

  async ngOnInit() {
    this.form.reset();

    this.busy = true;
    this.captcha = await this.cs.getCaptcha();
    this.busy = false;

    this.form.patchValue(this.captcha);
    if (this.captcha.validated) {
      this.form.get('text').clearValidators();
    } else {
      this.form.get('text').setValidators(Validators.required);
    }
    this.form.get('text').updateValueAndValidity();
  }

  getSvg() {
    if (!this.captcha || !this.captcha.svg) return '';
    return this.san.bypassSecurityTrustHtml(this.captcha.svg);
  }

  refresh() {
    return this.ngOnInit();
  }

  async handleError(err: any) {
    const code = getErrorCode(err);
      if (code === "cant-validate-captcha" || code === "cant-use-captcha"){
        this.store.removeItem('captcha');
        await this.refresh();
        const c = this.form.get('text');
        c.markAsTouched();
        c.setErrors({ 'captcha-invalid': true });
      }
  }

}
