import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Deferred } from '../../model/Deferred';
import { Document } from '../../model/Document';
import { Contact, InvoiceLine } from '../../model/Invoice';
import { PoffSessionResponse } from '../../model/SessionResponse';
import { Company } from '../../model/Company';
import { User, Team } from '../../model/User';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';
import { StoreService } from './store.service';
import * as moment from 'moment';
import { Captcha } from 'src/model/Captcha';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _readyDeferred = new Deferred<void>();
  private _started = false;

  get ready() {
    return this._readyDeferred.promise;
  }

  get user(): User {
    return this.userChanged.value;
  }

  get team(): Team {
    return this.teamChanged.value;
  }

  userChanged = new BehaviorSubject<User>(null);
  teamChanged = new BehaviorSubject<Team>(null);

  get loggedIn(): boolean {
    return Boolean(this.userChanged.value);
  }

  constructor(
    private api: ApiService,
    private store: StoreService,
    private router: Router,
    private cache: CacheService,
  ) {

  }

  async init() {
    if (this._started) return this._readyDeferred.promise;
    this._started = true;

    const user = this.store.getItem<User>('user', User);
    const teamId = this.store.getItem<number>('teamId');
    await this.checkSession(user, teamId);

    this._readyDeferred.resolve();
    return this._readyDeferred.promise;
  }

  async update(data: Partial<User>) {
    const result = await this.api.put('users', data);
    this.userChanged.next(User.deserialize(result.user));
  }

  async setAvatar(d: Document) {
    this.user.avatar = d;
    this.user.avatarId = d.id;
    this.user.avatarUrl = d.getPath();

    await this.api.put('users', { uuid: this.user.uuid, avatarId: d.id });

    this.userChanged.next(this.user);
  }

  async updateTeam(data: Partial<Team>) {
    const result = await this.api.put('teams', data);
    this.teamChanged.next(Team.deserialize(result.team));
  }

  async setTeamAvatar(d: Document) {
    this.team.avatar = d;
    this.team.avatarId = d.id;
    this.team.avatarUrl = d.getPath();

    await this.api.put('teams', { id: this.team.id, avatarId: d.id });

    this.userChanged.next(this.user);
  }

  logout() {
    this.store.removeItem('user');
    this.store.removeItem('teamId');
    this.api.unsetToken();
    this.userChanged.next(null);
    this.teamChanged.next(null);

    window.location.href = "/";
  }

  async changePassword(oldPassword: string, password: string) {
    await this.api.put("users/password", { uuid: this.user.uuid, oldPassword, password });
  }

  async resetPassword(token: string, password: string) {
    const uuid = this.user ? this.user.uuid : undefined;
    await this.api.put("password", { uuid, token, password });
  }

  async confirmEmail(token: string) {
    await this.api.post("email-confirmation", { token });
  }

  async login(creds: any): Promise<PoffSessionResponse> {
    const data = await this.api.post('session', creds);
    const loginResult = PoffSessionResponse.deserialize(data);
    this.processLogin(loginResult);

    return loginResult;
  }

  async signup(creds: any): Promise<PoffSessionResponse> {
    const data = await this.api.post('register', creds);
    const loginResult = PoffSessionResponse.deserialize(data);
    this.processLogin(loginResult);

    return loginResult;
  }

  async switchTeam(teamId: number) {
    return this.checkSession(this.user, teamId);
  }

  private processLogin(result: PoffSessionResponse) {
    if (!result.user.session) return false;

    //session
    this.store.setItem('user', result.user);
    this.api.setToken(result.user.session.token);

    this.store.setItem('teamId', result.team.id);
    this.api.setTeam(result.team.id);

    const templates = result.templates.map(d => InvoiceLine.deserialize(d));
    this.cache.templates.next(templates);

    const contacts = result.recentContacts.map(d => Contact.deserialize(d));
    this.cache.recentContacts.next(contacts);

    const companies = result.companies.map(d => Company.deserialize(d));
    this.cache.companies.next(companies);

    this.cache.bankAccounts.next(result.bankAccounts);


    this.cache.currencies.next(result.currencies);

    this.cache.teams.next(result.teams);
    this.teamChanged.next(result.team);
    this.userChanged.next(result.user);

    return true;
  }

  private async checkSession(user: User, teamId?: number): Promise<boolean> {
    if (!user || !user.session) return false;
    if (user.session.expires.isBefore()) return false;

    try {
      const param = teamId ? `?teamId=${teamId}` : '';
      const data = await this.api.get(`session/${user.session.token}${param}`);
      const result = PoffSessionResponse.deserialize(data);
      return this.processLogin(result);
    } catch (err) {
      return false;
    }
  }

  async requestPasswordReset(data: any) {
    return this.api.post('password-reset-request', data);
  }

  async resetPasswordWithToken(password: string, token: string) {
    return this.api.post('password-reset', { password, token });
  }
}
