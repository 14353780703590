<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <app-contact-search-form [control]="control"></app-contact-search-form>

  <!-- <form (ngSubmit)="submit()">
    <div>
      <app-typeahead-control (doSearch)="search($event)" label="displayName" [prefix]="prefix + 'contact'"
        [control]="control"></app-typeahead-control>
    </div>

    
  </form> -->
</div>
<div class="modal-footer">
  <button type="button" (click)="close()" class="btn btn-link mr-auto">{{ prefix + 'close' | translate }}</button>
  <button type="button" (click)="submit()" class="btn btn-primary btn-min-width">{{ prefix + 'ok' | translate }}</button>
</div>