<div class="text-center">
  <div class="card switch-container shadow">

    <div class="card-body text-center">

      <div class="row align-items-center">
        <div class="col">
          <img [src]="getAvatar(from?.avatar)" class="avatar">
        </div>
        <div class="col">
          <img src="assets/img/arrow-right.svg">
        </div>
        <div class="col">
          <img [src]="getAvatar(to?.avatar)" class="avatar">
        </div>
      </div>

      <div class="mt-4 pt-4">{{ prefix + 'switching' | translate }}</div>

    </div>
  </div>
</div>